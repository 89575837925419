import axios from 'axios'
import router from '@/router'
import autoLogin from '../helpers/autologin'

axios.interceptors.request.use(function (config) {
  config.baseURL = `${process.env.VUE_APP_URL}`
  const token = localStorage.getItem('token')

  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }
  config.headers.Accept = 'application/json'
  return config
}, function (error) {
  return Promise.reject(error)
})

axios.interceptors.response.use(function (response) {
  return response
}, function (error) {
  if (!error.response) {
    localStorage.removeItem('token')
    router.push({ name: 'ErrorPage' })
  } else if (error.response.status === 401) {
    localStorage.removeItem('token')
    autoLogin(() => {
      router.go(0)
    })
  } else if (error.response.status === 500 || error.response.status === 400) {
    localStorage.removeItem('token')
    router.push({ name: 'ErrorPage' })
  }
  return Promise.reject(error)
})

export default axios
