<template>
  <v-card width="450px" max-height="500px">
    <v-card-title>
      Buscar domicilio
    </v-card-title>
    <v-card-subtitle>
      Buscá tu domicilio para calcular el costo de envío
    </v-card-subtitle>
    <v-card-text v-if="cargandoGoogleMap">
      <div class="text-center">
      <v-progress-circular
        :size="50"
        color="primary"
        indeterminate
      ></v-progress-circular>
      </div>
    </v-card-text>
    <v-card-text v-else>
    <GmapAutocomplete :types="['address']" :componentRestrictions="{ country: 'AR' }" @place_changed="setPlace" />
    <GmapMap
      :options="{
        streetViewControl: false,
        fullscreenControl: false
      }"
      :center="center"
      :zoom="zoom"
      style="width: 100%; height: 200px; margin-top: 20px"
    >
      <GmapMarker
        :key="index"
        v-for="(m, index) in markers"
        :position="m.position"
        @click="center = m.position"
      />
    </GmapMap>
    <p class="pa-0 ma-0 mt-2 font-weight-bold red--text text-left" v-show="domicilioNoEncontrado">No repartimos en tu zona. Pero podés retirar tu pedido por el local.</p>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn ref="btnCrearMarcador" id="crearMarcador" text color="primary" @click="addMarker" v-if="!markCreated" :disabled="!currentPlace" :loading="loading" key="btn_buscar_map">BUSCAR EN EL MAPA</v-btn>
      <v-btn ref="btnConfirmarDireccion" id="confirmarDireccion" text color="primary" @click="onAddressConfirmed" :loading="loading" :disabled="loading" v-if="!domicilioNoEncontrado && markCreated" key="btn_confirmar_map">CONFIRMAR DIRECCIÓN</v-btn>
      <v-btn ref="btnRetirarPedido" v-if="domicilioNoEncontrado" color="primary" text @click="retirar">retirar tu pedido</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import { gmapApi } from 'vue2-google-maps'
import { mapActions } from 'vuex'
import { bus } from '@/helpers/bus.js'

export default {
  name: 'GoogleMapSelector',
  computed: {
    google: gmapApi
  },
  async mounted () {
    const { data } = await this.getZonas()
    this.$gmapApiPromiseLazy().then(() => {
      const { Polygon } = this.google.maps
      this.zonas = data.map((e) => {
        return {
          id: e.id,
          Nombre: e.Nombre,
          Color: e.Color,
          Precio: e.Precio,
          Lista: e.ListaCoordenada
        }
      })
      this.poligonos = this.zonas.map(e => {
        return new Polygon({
          paths: e.Lista.map(l => {
            return {
              lat: parseFloat(l.Latitud),
              lng: parseFloat(l.Longitud)
            }
          }),
          strokeColor: e.Color,
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: e.Color,
          fillOpacity: 0.35
        })
      })
      this.cargandoGoogleMap = false
    })
  },
  beforeDestroy () {
    this.currentPlace = null
    this.loading = false
  },
  data () {
    return {
      center: { lat: -31.412115135505516, lng: -64.18775411971284 },
      currentPlace: null,
      markers: [],
      zoom: 13,
      loading: false,
      markCreated: false,
      zonas: [],
      poligonos: [],
      domicilioNoEncontrado: false,
      cargandoGoogleMap: true
    }
  },
  methods: {
    ...mapActions({
      getZonas: 'App/setZonasCliente'
    }),
    setPlace (place) {
      this.domicilioNoEncontrado = false
      this.currentPlace = place
    },
    addMarker () {
      if (this.currentPlace) {
        this.loading = true
        setTimeout(() => {
          const marker = {
            lat: this.currentPlace.geometry.location.lat(),
            lng: this.currentPlace.geometry.location.lng()
          }
          this.markers.push({ position: marker })
          this.center = marker
          this.zoom = 18
          this.markCreated = true
          this.loading = false
        }, 1500)
      }
    },
    retirar () {
      bus.$emit('retirar-pedido', true)
      this.currentPlace = null
      this.$emit('cerrar-map-selector')
    },
    onAddressConfirmed () {
      this.loading = true
      let costoEnvio = 0
      let fueraRangoEntrega = true
      for (const indice in this.poligonos) {
        const poligono = this.poligonos[indice]
        const resultPath = this.google.maps.geometry.poly.containsLocation(
          this.currentPlace.geometry.location,
          poligono
        )
        if (resultPath) {
          costoEnvio = parseInt(this.zonas[indice].Precio)
          fueraRangoEntrega = false
          bus.$emit('onDomicilioGoogleMapSelectorChange', this.currentPlace.name)
          this.$emit('fuera-rango-entrega', fueraRangoEntrega)
          bus.$emit('nuevoCostoEnvio', costoEnvio)
          setTimeout(() => {
            this.$emit('cerrar-map-selector')
            this.currentPlace = null
          }, 1000)
          return
        }
      }
      this.loading = false
      this.domicilioNoEncontrado = true
    }
  }
}
</script>
<style scoped>
.pac-target-input {
  margin-top: 10px;
  padding: 10px;
  width: 100%;
}
</style>
