<template>
  <v-container>
    <v-row class="mt-4" v-show="!loading">
      <v-col v-for="(p,i) in localProducts" :key="`product_${i}`" cols="3">
        <product-card :product="p"></product-card>
      </v-col>
    </v-row>
    <v-row class="mt-4" v-if="loading">
      <v-col v-for="i in 8" cols="3" :key="`${i}ab`">
        <v-skeleton-loader
            v-bind="attrs"
            type="image, actions"
            :loading="loading">
        </v-skeleton-loader>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapActions } from 'vuex'
import ProductCard from '@/components/cards/ProductCard.vue'

export default {
  components: { ProductCard },
  name: 'ProductListHome',
  props: {
    categoriaId: {
      type: Number
    }
  },
  methods: {
    ...mapActions('Productos', ['fetchProducts'])
  },
  async mounted () {
    this.loading = true
    this.localProducts = await this.fetchProducts(this.categoriaId)
    this.loading = false
  },
  data () {
    return {
      loading: false,
      localProducts: []
    }
  }
}
</script>
