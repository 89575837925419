import mapItemToCartItem from '@/helpers/mapItemToCartItem'

const mutations = {
  setItem (state, item) {
    const existingItem = state.items.findIndex(i => i.productId === item.id)
    if (existingItem > -1) {
      if (state.items[existingItem].cant !== item.cant && !state.items[existingItem].esCombinado) {
        // state.items[existingItem].cant = item.cant
        state.items[existingItem] = mapItemToCartItem(item)
      } else {
        const _cartItem = mapItemToCartItem(item)
        state.items.push(_cartItem)
      }
    } else {
      const _cartItem = mapItemToCartItem(item)
      state.items.push(_cartItem)
    }
  },
  setItems (state, items) {
    state.items.concat(items)
  },
  eraseItem (state, itemToRemove) {
    for (let i = 0; i < state.items.length; i++) {
      if (state.items[i]._id === itemToRemove._id) { state.items.splice(i, 1) }
    }
  },
  eraseItemCarta (state, itemToRemove) {
    for (let i = 0; i < state.items.length; i++) {
      if (state.items[i].productId === itemToRemove.id) { state.items.splice(i, 1) }
    }
  },
  calculateAmountWhenItemIsAdded (state) {
    const currentAmounts = state.items.map((item) => { return item.cant * item.precio })
    state.total = currentAmounts.reduce((a, b) => a + b, 0)
  },
  calculateAmountWhenItemIsRemoved (state, itemToRemove) {
    state.total -= (itemToRemove.cant * itemToRemove.precio)
  },
  resetItems (state) {
    state.items = []
    state.total = 0
  },
  editItem (state, params) {
    const existingItem = state.items.findIndex(i => i.productId === params.id)
    if (existingItem > -1) {
      const item = state.items[existingItem]
      item.canApplyDiscoint = params.applyDiscoint
      state.items[existingItem] = item
    }
  }
}

export default mutations
