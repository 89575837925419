<template>
  <v-card-actions>
    <buy-product></buy-product>
    <v-spacer></v-spacer>
    <!-- <v-btn color="primary" icon>
      <v-icon>mdi-eye</v-icon>
    </v-btn>
    <v-btn color="primary" icon>
      <v-icon>mdi-share-variant</v-icon>
    </v-btn> -->
  </v-card-actions>
</template>
<script>
import BuyProduct from './BuyProduct.vue'
export default {
  components: { BuyProduct },
  name: 'ProductActions',
  props: {
    product: {
      type: Object
    }
  }
}
</script>
