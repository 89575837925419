<template>
    <v-dialog transition="dialog-bottom-transition" max-width="450" persistent v-model="open">
        <cupon-card @cerrar-cupon-card="$emit('update:open',false)"></cupon-card>
    </v-dialog>
</template>
<script>
import CuponCard from '../cards/CuponCard.vue'
export default {
  components: { CuponCard },
  name: 'CuponDialog',
  props: {
    open: {
      type: Boolean,
      default: false
    }
  }
}
</script>
