<template>
  <v-container>
    <div class="text-center">
      <h1>Se ha producido un error en la búsqueda o solicitud.</h1>
      <h3>Lo sentimos, no pudimos procesar la solicitud.</h3>
      <v-btn color="primary" @click="goToTiendas">IR A INICIO</v-btn>
    </div>
  </v-container>
</template>
<script>
export default {
  name: 'ErrorPage',
  methods: {
    goToTiendas () {
      if (this.$isAndroidOrIos()) {
        this.$router.push({ name: 'Tiendas' })
      } else {
        this.$router.push({ name: 'Home' })
      }
    }
  }
}
</script>
