<template>
  <v-card flat exact height="380px">
  <p>Forma de entrega</p>
  <v-radio-group ref="formaDeEntrega" v-model="formasEntregaGroup">
  <v-radio
    v-for="formaEnvio in clientData.formasEnvio"
    :key="`formaEnvio_${formaEnvio.id}`"
    :label="formaEnvio.name"
    :value="formaEnvio.id"
    @change="(minimo = formaEnvio.minimo),(showTextoImporteMinimo = formaEnvio.minimo > total),$emit('importeMinimoChange', formaEnvio.minimo > total)"
  ></v-radio>
  </v-radio-group>
  <p>Forma de pago</p>
  <v-radio-group ref="formaDePago" v-model="formasPagoGroup">
    <v-radio
      v-for="formaPago in clientData.formasPago"
      :key="`formaPago_${formaPago.id}`"
      :label="formaPago.name"
      :value="formaPago.id"
      @change="mostrarTexto(formaPago.Texto)"
    ></v-radio>
  </v-radio-group>
  <h5 v-if="showTextoFormaPago">{{ textoFormaPago }}</h5>
  <h5 v-if="descuento">{{`Tenes un descuento disponible del ${descuento.PorcentajeDesct}%.`  }}</h5>
  <h5 v-if="showTextoImporteMinimo" class="error--text">{{`El mínimo de compra es $${minimo}.`  }}</h5>
  </v-card>
</template>
<script>
/* eslint-disable */
import { mapGetters } from 'vuex'
import { bus } from '@/helpers/bus.js'
import descuentoHelper from '@/helpers/descuentosHelper.js'

export default {
  name: 'FormasPagoEntrega',
  mounted () {
    this.formasPagoGroup = this.clientData && this.clientData.formasPago.length === 1 ? this.clientData.formasPago[0].id : this.clientData && this.clientData.formasPago.length > 0
      ? this.clientData.formasPago.filter((e) => e.default).length > 0 ? this.clientData.formasPago.filter((e) => e.default)[0].id : this.clientData.formasPago.filter((e) => e.id === 1)[0].id : null
    this.formasEntregaGroup = this.clientData && this.clientData.formasEnvio.length === 1
      ? this.clientData.formasEnvio[0].id : this.clientData && this.clientData.formasEnvio.length > 0
        ? this.clientData.formasEnvio.filter((e) => e.default).length > 0
          ? this.clientData.formasEnvio.filter((e) => e.default)[0].id : this.clientData.formasEnvio.filter((e) => e.name.includes('Retira en'))[0].id : null
    bus.$on('retirar-pedido', (event) => {
      if (event) this.formasEntregaGroup = this.clientData.formasEnvio.filter((e) => e.name.includes('Retira en'))[0].id
    })
    this.descuento = descuentoHelper.getDescuento(this.formasPagoGroup)
    if (this.clientData.formasEnvio.filter(e => e.id === this.formasEntregaGroup)[0].minimo > this.total) {
      this.$emit('importeMinimoChange', true)
      this.minimo = this.clientData.formasEnvio.filter(e => e.id === this.formasEntregaGroup)[0].minimo
    }
  },
  computed: {
    ...mapGetters({
      clientData: 'App/data',
      total: 'Carrito/getTotal'
    })
  },
  methods: {
    onFormaPagoChange () {
      let botonPago = document.getElementById('botonmercadopago')
      let mercadoPagoHeader = document.getElementById('mercadopago')
      if (this.formasPagoGroup === 2) {
        if (!botonPago) {
          botonPago = document.createElement('div')
          botonPago.className = 'pago-mp'
          botonPago.style = 'display:none'
          botonPago.id = 'botonmercadopago'
          document.body.appendChild(botonPago)
        }
        if (!mercadoPagoHeader || mercadoPagoHeader.length === 0) {
          mercadoPagoHeader = document.createElement('script')
          mercadoPagoHeader.src = 'https://sdk.mercadopago.com/js/v2'
          mercadoPagoHeader.id = 'mercadopago'
          mercadoPagoHeader.type = 'text/javascript'
          document.head.appendChild(mercadoPagoHeader)
        }
      } else {
        if (botonPago) botonPago.remove()
        if (mercadoPagoHeader) mercadoPagoHeader.remove()
      }
      this.descuento = this.clientData.Descuentos.filter(d => d.FPagoId === this.formasPagoGroup)[0]
    },
    mostrarTexto (texto) {
      this.showTextoFormaPago = texto
      this.textoFormaPago = texto
    }
  },
  data () {
    return {
      formasEntregaGroup: null,
      formasPagoGroup: null,
      showTextoFormaPago: false,
      textoFormaPago: null,
      descuento: null,
      showTextoImporteMinimo: false,
      minimo: 0
    }
  },
  watch: {
    formasEntregaGroup (nuevaFormaEntrega, viejaFormaEntrega) {
      bus.$emit('onFormaEntregaChangedBus', nuevaFormaEntrega)
    },
    formasPagoGroup (nuevaFormaPago, viejaFormaPago) {
      bus.$emit('onFormaPagoChangedBus', nuevaFormaPago)
      this.onFormaPagoChange()
    }
  }
}
</script>
