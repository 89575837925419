import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Categorias from '../views/Categorias.vue'
import Checkout from '../views/Checkout.vue'
import PageNotFound from '../views/PageNotFound.vue'
import Tiendas from '../views/Tiendas.vue'
import isMobile from '../helpers/isMobile'
import ErrorPage from '../views/ErrorPage.vue'
import Franquicias from '../views/Franquicias.vue'
import ProductosMobile from '../views/ProductosListMobile.vue'
import store from '@/store/index'
import validateClient from '../helpers/validarCliente'
import actualizarPedido from '../helpers/actualizarPedido'
import Producto from '../views/Producto.vue'
import EstadoPedido from '../views/EstadoPedido.vue'
import MesaQR from '../views/MesaQR.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Info',
    beforeEnter (to, from, next) {
      if (!isMobile()) window.location.href = 'https://info.clickaway.app/'
      if (process.env.VUE_APP_LISTAR_FRANQUICIAS === 'TRUE') {
        next({
          name: 'Sucursales',
          params: {
            id: process.env.VUE_APP_FRANQUICIA
          }
        })
      } else {
        next({ name: 'Tiendas' })
      }
    }
  },
  {
    path: '/tienda/:client',
    name: 'Home',
    component: Home,
    beforeEnter: async (to, from, next) => {
      const code = to.params.client
      const { Status } = await validateClient(to.params.client)
      if (Status === 'ok' || Status === 'cerrado') {
        store.dispatch('App/setAppLogo', code)
        store.dispatch('App/setDataClient', code)
        store.dispatch('App/setAppPreviousPage', from.name)
      } else {
        next({ name: 'PageNotFound' })
      }
      next()
    }
  },
  {
    path: '/tienda/:client/mis-pedidos/:id',
    name: 'VerPedido',
    component: EstadoPedido
  },
  {
    path: '/tienda/:client/mesa/:mesaId',
    name: 'MesaQR',
    component: MesaQR,
    beforeEnter: async (to, from, next) => {
      if (!isMobile()) {
        next({ name: 'PageNotFound' })
      }
      const code = to.params.client
      const { Status } = await validateClient(to.params.client)
      if (Status !== 'ok' || Status === 'cerrado') {
        next({ name: 'PageNotFound' })
      }
      store.dispatch('App/setAppLogo', code)
      store.dispatch('App/setDataClient', code)
      store.dispatch('App/setAppPreviousPage', from.name)
      next()
    }
  },
  {
    path: '/tienda/:client/mesa/:mesaId/productos',
    name: 'MesaQRProductos',
    component: ProductosMobile,
    beforeEnter (to, from, next) {
      if (!isMobile()) {
        next({ name: 'PageNotFound' })
      }
      next()
    }
  },
  {
    path: '/tienda/:client/checkout/exito',
    name: 'CheckoutExitoso',
    beforeEnter: async (to, from, next) => {
      localStorage.setItem('queryMercadoPago', JSON.stringify(to))
      // eslint-disable-next-line
      const { external_reference } = to.query
      const estado = 1
      const response = await actualizarPedido(to.params.client, external_reference, estado)
      if (response) {
        next({ name: 'VerPedido', params: { id: external_reference } })
      }
    }
  },
  {
    path: '/tienda/:client/checkout/error',
    name: 'CheckoutError',
    beforeEnter: async (to, from, next) => {
      localStorage.setItem('queryMercadoPago', JSON.stringify(to))
      // eslint-disable-next-line
      const { external_reference } = to.query
      const estado = 9
      const response = await actualizarPedido(to.params.client, external_reference, estado)
      if (response) {
        next({ name: 'VerPedido', params: { id: external_reference } })
      }
    }
  },
  {
    path: '/tienda/:client/checkout/pendiente',
    name: 'CheckoutPendiente',
    beforeEnter (to, from, next) {
      //  eslint-disable-next-line
      const { external_reference } = to.query
      next({ name: 'VerPedido', params: { id: external_reference } })
    }
  },
  {
    path: '/tienda/:client/categorias',
    name: 'Categorias',
    component: Categorias
  },
  {
    path: '/tienda/:client/productos',
    name: 'ProductosMobile',
    component: ProductosMobile
  },
  {
    path: '/tienda/:client/productos/:id',
    name: 'Producto',
    component: Producto,
    props: true
  },
  {
    path: '/tienda/:client/mesa/:mesaId/productos/:id',
    name: 'ProductoQR',
    component: Producto,
    props: true
  },
  {
    path: '/tiendas',
    name: 'Tiendas',
    component: Tiendas,
    beforeEnter: async (to, from, next) => {
      store.commit('App/setTiendaActual', null)
      store.commit('App/setData', null)
      store.commit('App/setLogo', null)
      store.commit('Carrito/resetItems')
      document.title = 'Clickaway'
      if (!isMobile()) window.location.href = 'https://info.clickaway.app/'
      next()
    }
  },
  {
    path: '/tienda/:client/checkout',
    name: 'Checkout',
    component: Checkout
  },
  {
    path: '*',
    name: 'PageNotFound',
    component: PageNotFound,
    beforeEnter: async (to, from, next) => {
      const code = to.params.pathMatch.replace('/', '')
      const { Status } = await validateClient(code)
      if (Status === 'ok' || Status === 'cerrado') {
        store.dispatch('App/setAppLogo', code)
        store.dispatch('App/setDataClient', code)
        store.dispatch('App/setAppPreviousPage', 'Home')
        next({ name: 'Home', params: { client: code } })
      } else {
        next()
      }
    }
  },
  {
    path: '/tienda/:client/error',
    name: 'ErrorPage',
    component: ErrorPage
  },
  {
    path: '/sucursales/:id',
    name: 'Sucursales',
    component: Franquicias,
    props: true
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
