import store from '@/store'

const helper = {
  getDescuento: (pagoId) => {
    if (pagoId) {
      return store.getters['App/data'].Descuentos.filter(d => d.FPagoId === pagoId)[0]
    }
  },
  existeCupon: () => {
    return store.getters['App/data'].Descuentos.filter(d => d.CodigoCupon === 'CodigoCupon').length > 0
  },
  precioConDescuento (product) {
    const precio = product.PrecioUnitario || product.precio || product.totalPrice
    const { GrupoDescuento, Descuentos } = product
    if (GrupoDescuento) {
      if (GrupoDescuento && GrupoDescuento.Cantidad > -1 && product.cant) {
        const productosGrupo = store.getters['Carrito/getItems'].filter(p => p.GrupoDescuento.GrupoId === GrupoDescuento.GrupoId && product.id !== p.productId)
        const cantidadGrupo = productosGrupo.reduce((a, b) => a + b.cant, 0) + product.cant
        productosGrupo.forEach(e => {
          store.dispatch('Carrito/editItem', {
            id: e.productId,
            PorcentajeDesct: GrupoDescuento.PorcentajeDesct,
            applyDiscoint: cantidadGrupo >= GrupoDescuento.Cantidad
          })
        })
        return precio
      } else if (GrupoDescuento && GrupoDescuento.Cantidad === -1) {
        return (precio - ((precio * GrupoDescuento.PorcentajeDesct) / 100)).toFixed(2)
      }
    }
    if (Descuentos) {
      if (Descuentos.length > 0) {
        return precio - ((precio * product.Descuentos[0].PorcentajeDesct) / 100)
      }
    }
    return precio
  },
  calcularDescuentos: (pagoId, neto) => {
    const calculoDescuentos = {
      descuentoTotal: 0.00,
      hayDescuentos: []
    }
    const grupoPorCantidad = []
    // busco los productos que tienen descuento
    store.getters['Carrito/getItems'].forEach(item => {
      console.log(item)
      if (item.descuentoPorGrupoCantidad) {
        calculoDescuentos.hayDescuentos.push('Grupo con cantidad')
        if (item.canApplyDiscoint && !grupoPorCantidad.find(g => g === item.GrupoDescuento.GrupoId)) {
          grupoPorCantidad.push(item.GrupoDescuento.GrupoId)
          let descuentoPorCantidad = 0
          let totalTmp = 0
          let cantidadParaAplicarDescuento = 0
          const productosGrupo = store.getters['Carrito/getItems'].filter(p => p.GrupoDescuento.GrupoId === item.GrupoDescuento.GrupoId && item.id !== p.productId)
          for (let i = 0; i < productosGrupo.length; i++) {
            for (let k = 0; k < productosGrupo[i].cant; k++) {
              cantidadParaAplicarDescuento += 1
              totalTmp += productosGrupo[i].precio
              if (cantidadParaAplicarDescuento === item.GrupoDescuento.Cantidad) {
                // en esta variable queda siempre el ultimo descuento calculado
                descuentoPorCantidad = parseFloat(((totalTmp * item.GrupoDescuento.PorcentajeDesct) / 100).toFixed(2))
                cantidadParaAplicarDescuento = 0
              }
            }
          }
          calculoDescuentos.descuentoTotal += descuentoPorCantidad
        }
      } else if (item.descuentoPorGrupo) {
        calculoDescuentos.hayDescuentos.push('Grupo sin cantidad')
        if (item.precio !== item.discoint) calculoDescuentos.descuentoTotal += item.cant * (item.precio - item.discoint)
      } else if (item.descuentoPorProducto) {
        calculoDescuentos.hayDescuentos.push('Producto')
        if (item.precio !== item.discoint) calculoDescuentos.descuentoTotal += item.cant * (item.precio - item.discoint)
      }
    })
    // busco por forma de pago
    if (pagoId) {
      const descFormaPago = store.getters['App/data'].Descuentos.filter(d => d.FPagoId === pagoId)[0]
      if (descFormaPago) {
        calculoDescuentos.hayDescuentos.push('FormaPago')
        calculoDescuentos.descuentoTotal += ((neto * descFormaPago.PorcentajeDesct) / 100)
      }
    }
    // busco el cupon aplicado
    const descCupon = store.getters['App/appCupon']
    if (descCupon) {
      calculoDescuentos.hayDescuentos.push('Cupon')
      calculoDescuentos.descuentoTotal += ((neto * descCupon.PorcentajeDesct) / 100)
    }
    return calculoDescuentos
  }
}

export default helper
