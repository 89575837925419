<template>
<v-card
    class="mx-auto mb-4"
    max-width="344"
    outlined
  >
    <v-img
      :src="product.detalle.srcImg"
      @click="viewProduct"
      >
    </v-img>
    <v-card-title>{{ product.nombre }}</v-card-title>
    <v-card-subtitle v-if="descuento !== product.precio"><span class="text-h5">$ {{ descuento }}&nbsp;</span><del class="" > ${{product.precio}}</del></v-card-subtitle>
    <v-card-subtitle v-else><span class="title">$ {{product.precio}}</span></v-card-subtitle>
    <v-card-text>
      <span>{{ product.descripcion }}</span>
    </v-card-text>
    <product-actions :product="product"></product-actions>
  </v-card>
</template>
<script>
import ProductActions from '@/components/ProductActions.vue'
import descuentoHelper from '@/helpers/descuentosHelper.js'

export default {
  name: 'ProductMobileCard',
  components: { ProductActions },
  props: {
    product: {
      type: Object
    }
  },
  computed: {
    descuento () {
      return descuentoHelper.precioConDescuento(this.product)
    }
  },
  methods: {
    viewProduct () {
      if (this.product.tipo !== 1) {
        const code = this.$clientCode()
        this.$router.push({
          name: 'Producto',
          params: {
            client: code,
            id: this.product.id
          }
        })
      }
    }
  }
}
</script>
<style scoped>
.v-card__text, .v-card__title {
  word-break: normal;
}
</style>
