const mutations = {
  setProducts (state, val) {
    state.all = val
  },
  setProduct (state, val) {
    state.product = val
  },
  setDestacados (state, val) {
    state.destacados = val
  }
}

export default mutations
