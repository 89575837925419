<template>
  <v-container>
    <div v-if="$isMobile()">
      <v-skeleton-loader type="list-item-three-line" :loading="loading" v-show="loading"></v-skeleton-loader>
      <div v-if="accesoDenegadoUsuario">
        <v-alert type="warning" outlined>
          <p>Has denegado el acceso a tu ubicacion. No podremos mostrarte las tiendas mas cercanas.</p>
        </v-alert>
      </div>
      <div v-if="!loading">
        <lista-tiendas-mobile :tiendas="tiendas"></lista-tiendas-mobile>
      </div>
    </div>
  </v-container>
</template>
<script>
import ListaTiendasMobile from '../components/mobile/ListaTiendasMobile.vue'
import { mapActions } from 'vuex'
import { Plugins } from '@capacitor/core'

const { Geolocation } = Plugins

export default {
  components: { ListaTiendasMobile },
  name: 'Tiendas',
  methods: {
    ...mapActions('App', ['getTiendas']),
    ...mapActions('App', ['setAppCustomerPosition']),
    async getCustomerPosition (cords) {
      const { latitude, longitude } = cords.coords
      this.setAppCustomerPosition({
        latitude,
        longitude
      })
      this.tiendas = await this.getTiendas()
      this.loading = false
    },
    async handlerGeopositionErros (erros) {
      if (erros.code === 1) {
        this.loading = false
        this.accesoDenegadoUsuario = true
        this.tiendas = await this.getTiendas()
      }
    },
    getPermissions () {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(this.getCustomerPosition, this.handlerGeopositionErros)
      } else {
        Geolocation.getCurrentPosition().then(coordinates => this.getCustomerPosition(coordinates)).catch(e => alert(e))
      }
    }
  },
  data () {
    return {
      tiendas: [],
      franquicias: [],
      loading: false,
      accesoDenegadoUsuario: false
    }
  },
  async mounted () {
    this.loading = true
    this.getPermissions()
  }
}
</script>
