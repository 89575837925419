<template>
  <div>
    <h4>Podés seleccionar un importe de la lista o <a @click.prevent="seleccionarImporte = false">escribir un importe.</a></h4>
    <v-chip-group
        v-model="importeSeleccionado"
        active-class="deep-purple accent-4 white--text"
        column
        v-if="seleccionarImporte"
      >
        <v-chip v-for="(importe, index) in importesCercanos" :key="`chip_importe_${index}`">{{ importe }}</v-chip>
      </v-chip-group>
      <v-text-field
        outlined
        color="primary"
        v-model="importeEscrito"
        prepend-inner-icon="mdi-cash"
        v-mask="'####.##'"
        v-else>
      </v-text-field>
  </div>
</template>
<script>
export default {
  name: 'ElegirImporteContado',
  mounted () {
    this.importe = this.total()
    const importeMasCercano = Math.ceil(this.importe / 100) * 100
    const denominaciones = [200, 500, 1000]
    const denominacionesMayorQueImporte = denominaciones.filter(e => e > importeMasCercano)
    denominacionesMayorQueImporte.push(importeMasCercano)
    this.importeSeleccionado = 0
    this.importesCercanos = denominacionesMayorQueImporte.sort((a, b) => {
      if (a > b) return 1
      if (a < b) return -1
      return 0
    })
  },
  props: {
    total: {
      type: Function,
      required: true
    }
  },
  data () {
    return {
      importe: null,
      importesCercanos: [],
      importeSeleccionado: null,
      importeEscrito: null,
      seleccionarImporte: true
    }
  },
  watch: {
    importeSeleccionado (val) {
      this.$emit('importe-elegido', this.importesCercanos[val])
    },
    importeEscrito (val) {
      this.$emit('importe-elegido', val)
    }
  }
}
</script>
