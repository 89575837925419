<template>
  <v-container>
    <h1 class="text-center">La página que buscas no se encuentra disponible.</h1>
    <v-btn color="primary" @click="$router.go(-1)">VOLVER</v-btn>
  </v-container>
</template>
<script>
export default {
  name: 'PageNotFound'
}
</script>
