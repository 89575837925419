const mutations = {
  setLoading (state, val) {
    state.appLoading = val
  },
  setMessage (state, val) {
    state.appMessage = val
  },
  setLogo (state, val) {
    state.appLogo = val
  },
  setData (state, val) {
    state.data = val
  },
  setFranquicias (state, val) {
    state.franquicias = val
  },
  setCostoEnvioPorZona (state, val) {
    state.costoEnvioPorZona = val
  },
  setLogoFranquicia (state, val) {
    state.appLogoFranquicia = val
  },
  setTiendaActual (state, val) {
    state.tiendaActual = val
  },
  setCustomerPosition (state, val) {
    state.customerPosition = val
  },
  setPreviousPage (state, val) {
    state.previousPage = val
  },
  setFranquiciaSeleccionada (state, val) {
    state.franquiciaSeleccionada = val
  },
  setCupon (state, val) {
    state.cupon = val
  }
}

export default mutations
