import descuentoHelper from '@/helpers/descuentosHelper.js'

const S4 = () => {
  return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
}

const mapItemToCartItem = (item) => {
  // si no hay descuento, viene el mismo precio
  const discointOrPrice = descuentoHelper.precioConDescuento(item)
  const guid = (S4() + S4() + '-' + S4() + '-4' + S4().substr(0, 3) + '-' + S4() + '-' + S4() + S4() + S4()).toLowerCase()
  if (item.esCombinadoCombo) {
    return {
      _id: guid,
      esCombinado: true,
      productId: item.id,
      nombre: item.nombre,
      cant: item.cant,
      precio: item.PrecioUnitario || item.precio || item.totalPrice,
      GrupoDescuento: item.GrupoDescuento,
      discoint: discointOrPrice,
      descuentoPorProducto: item.Descuentos?.length > 0,
      descuentoPorGrupo: item.GrupoDescuento !== null || item.GrupoDescuento !== undefined,
      descuentoPorGrupoCantidad: item.GrupoDescuento ? item.GrupoDescuento.Cantidad > 0 : false,
      observaciones: item.obs,
      img: item.detalle.srcImg,
      productos: item.items.map((element) => {
        return {
          id: element.id,
          cant: element.cant,
          nombre: element.name,
          precio: element.PrecioUnitario || element.precio || element.totalPrice,
          discoint: descuentoHelper.precioConDescuento(element),
          total: element.cant * element.PrecioUnitario,
          Parent: guid
        }
      })
    }
  } else if (item.esEstatico) {
    return {
      _id: guid,
      esEstatico: true,
      productId: item.id,
      nombre: item.nombre,
      cant: item.cant,
      GrupoDescuento: item.GrupoDescuento,
      descuentoPorProducto: item.Descuentos?.length > 0,
      precio: item.PrecioUnitario || item.precio || item.totalPrice,
      discoint: discointOrPrice,
      observaciones: item.obs,
      img: item.detalle.srcImg,
      descuentoPorGrupo: item.GrupoDescuento !== null || item.GrupoDescuento !== undefined,
      descuentoPorGrupoCantidad: item.GrupoDescuento ? item.GrupoDescuento.Cantidad > 0 : false,
      productos: item.childs.map((element) => {
        return {
          id: element.id,
          cant: element.CantMinima,
          nombre: element.nombre,
          precio: 0,
          total: 0,
          Parent: guid
        }
      })
    }
  } else {
    return {
      _id: guid,
      esCombinado: false,
      productId: item.id,
      nombre: item.nombre,
      GrupoDescuento: item.GrupoDescuento,
      descuentoPorProducto: item.Descuentos?.length > 0,
      descuentoPorGrupo: item.GrupoDescuento !== null || item.GrupoDescuento !== undefined,
      descuentoPorGrupoCantidad: item.GrupoDescuento ? item.GrupoDescuento.Cantidad > 0 : false,
      cant: item.cant,
      precio: item.PrecioUnitario || item.precio || item.totalPrice,
      discoint: discointOrPrice,
      img: item.detalle.srcImg
    }
  }
}

export default mapItemToCartItem
