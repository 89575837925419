<template>
  <div v-if="categorias">
    <div v-for="(c,i) in categorias.slice(0,10)" :key="`categoria_${i}`" class="mt-4">
      <h1 class="font-weight-light">{{ c.descripcion }}</h1>
      <v-divider></v-divider>
      <v-row class="mt-4">
        <productos-list-home :categoriaId="c.id"></productos-list-home>
      </v-row>
      <!-- subcategorias -->
      <div v-for="(sub,i) in c.subcategorias" :key="i" class="mt-4">
      <h3 class="font-weight-light">{{ sub.descripcion }}</h3>
      <v-divider></v-divider>
      <v-row class="mt-4">
        <productos-list-home :categoriaId="sub.id"></productos-list-home>
      </v-row>
    </div>
      <!-- fin subcategorias-->
      <div v-if="c.totalProducts > 8" class="mt-4 text-center">
        <v-btn color="secondary" text>Ver más en {{ c.name }}</v-btn>
      </div>
    </div>
    <div v-if="categorias.length > 10" class="mt-4 text-center">
        <v-btn color="secondary" text @click.prevent="$router.push({ name:'Categorias' })">Ver más categogrías</v-btn>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ProductosListHome from '../components/ProductListHome.vue'

export default {
  name: 'CategoriasHome',
  components: { ProductosListHome },
  computed: {
    ...mapGetters('Categorias', ['categorias'])
  },
  methods: {
    ...mapActions('Categorias', ['getCategorias'])
  },
  async mounted () {
    await this.getCategorias()
  }
}
</script>
