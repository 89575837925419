<template>
  <v-container>
    <categorias-home v-if="!$isMobile()"></categorias-home>
    <categorias-home-mobile v-else></categorias-home-mobile>
  </v-container>
</template>

<script>
import CategoriasHome from '../components/CategoriasHome.vue'
import CategoriasHomeMobile from '../components/mobile/CategoriasHomeMobile.vue'

export default {
  components: { CategoriasHome, CategoriasHomeMobile },
  name: 'Home',
  mounted () {
    this.$emit('showCart', true)
  }
}
</script>
