<template>
  <v-dialog
    transition="dialog-top-transition"
    max-width="600"
    :value="isOpen"
    persistent
  >
    <v-card flat>
      <v-toolbar flat>Mi pedido</v-toolbar>
      <v-card-text>
        <v-divider></v-divider>
        <v-card max-height="150px" class="overflow-y-auto" exact flat>
          <cart-details v-if="isOpen"></cart-details>
        </v-card>
        <v-divider></v-divider>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn text color="primary lighten-2" v-if="$route.name !== 'Checkout' && !data.NegocioCerrado" :disabled="getTotalItems === 0" @click.prevent="$router.push({ name: 'Checkout' }),$emit('update:isOpen', false)">Comprar</v-btn>
        <v-btn
          text
          @click="$emit('update:isOpen', false)"
        >Cerrar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters } from 'vuex'
import CartDetails from './CartDetails.vue'

export default {
  components: { CartDetails },
  name: 'CartDialog',
  computed: {
    ...mapGetters('Carrito', ['getTotalItems']),
    ...mapGetters('App', ['data'])
  },
  props: {
    isOpen: {
      default: false,
      type: Boolean
    }
  }
}
</script>
