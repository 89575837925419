<template>
  <v-hover
    v-slot="{ hover }"
    open-delay="200"
    close-delay="200"
  >
  <v-card :elevation="hover ? 16 : 2"
          :class="{ 'on-hover': hover }" outlined>
    <v-img
      :src="product.detalle.srcImg"
      class="white--text align-end"
      gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
      height="200px"
    >
      <v-card-title v-text="product.nombre"></v-card-title>
    </v-img>
    <v-card-text v-if="descuento !== product.precio">
      <div><span class="display-1">$ {{ descuento }}&nbsp;</span><del class="title" >${{product.precio}}</del></div>
      <p v-if="hover">{{ product.descripcion }}</p>
    </v-card-text>
    <v-card-text v-else>
      <div class="display-1">$ {{ product.precio }}</div>
      <p v-if="hover">{{ product.descripcion }}</p>
    </v-card-text>
    <product-actions :product="product"></product-actions>
  </v-card>
  </v-hover>
</template>
<script>
import ProductActions from '@/components/ProductActions.vue'
import descuentoHelper from '@/helpers/descuentosHelper.js'

export default {
  name: 'ProductCard',
  components: { ProductActions },
  props: {
    product: {
      type: Object
    }
  },
  computed: {
    descuento () {
      return descuentoHelper.precioConDescuento(this.product)
    }
  }
}
</script>
<style scoped>
.v-card__text, .v-card__title {
  word-break: normal; /* maybe !important  */
}
</style>
