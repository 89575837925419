<template>
    <v-list v-if="getItems.length > 0">
      <v-list-item
        v-for="(product,i) in getItems"
        :key="`cart_product_${i}`"
      >
        <v-list-item-avatar>
          <v-img
            :alt="product.nombre"
            :src="product.img"
          ></v-img>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title v-text="product.nombre"></v-list-item-title>
          <v-list-item-subtitle v-if="product.discoint !== product.precio">
            <div><span class="title">{{ `${product.cant} x $${product.discoint}` }}&nbsp;</span><del class="">${{product.precio}}</del></div>
          </v-list-item-subtitle>
          <v-list-item-subtitle v-text="`${product.cant} x $${product.precio}`" v-else></v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-icon v-if="$route.name !== 'Checkout'">
          <v-icon @click="onProductClick(product)">
            mdi-close-circle
          </v-icon>
        </v-list-item-icon>
      </v-list-item>
    </v-list>
    <v-container v-else>
      <span>Tu carrito esta vacio. Selecciona algun producto de la carta para completarlo.</span>
    </v-container>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'CartDetails',
  computed: {
    ...mapGetters('Carrito', ['getItems', 'getTotalItems'])
  },
  methods: {
    ...mapActions('Carrito', ['removeItem']),
    onProductClick (product) {
      this.removeItem(product)
    }
  }
}
</script>
