// import ordenarPorHsDesde from './ordernarPorHsDesde'

const date = new Date()
const dias = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sabado']
const dia = dias[date.getDay()]

const schedules = (horarios) => {
  const tmpHorarios = horarios.slice()
  const day = (date.getDay() === 0 ? 7 : date.getDay())
  const horariosFitrados = tmpHorarios.filter(e => e.filter(i => i.Id === day).length > 0)[0]
  if (horariosFitrados === undefined) return ''
  // horariosFitrados.sort(ordenarPorHsDesde)
  const primerHorario = horariosFitrados[0]
  if (horariosFitrados.length > 1) {
    const segundoHorario = horariosFitrados[1]
    return `${dia}: ${primerHorario.HsDesde} a ${primerHorario.HsHasta} | ${segundoHorario.HsDesde} a ${segundoHorario.HsHasta}`
  }
  return `${dia}: ${primerHorario.HsDesde} a ${primerHorario.HsHasta}`
}

export default schedules
