<template>
  <v-container>
    <go-back :routeName="previousPage"></go-back>
    <v-row class="mt-4" v-show="!loading">
      <v-col v-for="(p,i) in products" :key="i" cols="12">
        <product-mobile-card :product="p"></product-mobile-card>
      </v-col>
    </v-row>
    <v-row class="mt-4" v-if="loading">
      <v-col v-for="i in 3" cols="6" :key="`${i}ab`">
        <v-skeleton-loader
            v-bind="attrs"
            type="card"
            :loading="loading">
        </v-skeleton-loader>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import GoBack from '@/components/GoBack.vue'
import ProductMobileCard from '../components/cards/ProductMobileCard.vue'

export default {
  components: { GoBack, ProductMobileCard },
  name: 'ProductosListMobile',
  computed: {
    ...mapState('Categorias', ['currentCategoria']),
    ...mapState('App', ['tiendaActual'])
  },
  methods: {
    ...mapActions('Productos', ['fetchProducts'])
  },
  data () {
    return {
      loading: false,
      products: [],
      previousPage: null,
      mesaId: null
    }
  },
  async mounted () {
    const code = this.$clientCode()
    if (!this.currentCategoria) this.$router.push({ name: 'Home', params: { client: code } })
    if (!this.currentCategoria.id) this.$router.push({ name: 'Categorias', params: { client: code } })
    this.$vuetify.goTo(0)
    this.loading = true
    this.products = await this.fetchProducts(this.currentCategoria.id || this.currentCategoria)
    this.loading = false
    this.previousPage = this.$route.params.mesaId ? `/tienda/${code}/mesa/${this.$route.params.mesaId}` : `/tienda/${code}`
    this.mesaId = this.$route.params.mesaId
  }
}
</script>
