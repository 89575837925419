<template>
  <div>
    <v-divider class="mx-4"></v-divider>
    <v-list v-for="(grupoEstatico, i) in estaticos"  :key="`grupo_estatico_${i}`">
      <v-list-item
      v-for="(estatico, k) in grupoEstatico.productos"
      :key="`estatico_${k}`"
      >
        <v-list-item-avatar>
          <v-img :src="estatico.detalle.srcImg"></v-img>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title v-html="estatico.nombre"></v-list-item-title>
          <v-list-item-subtitle v-html="`Cantidad: ${estatico.CantMinima}`"></v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
</template>
<script>
export default {
  name: 'Estatico',
  props: {
    estaticos: {
      type: Object
    }
  }
}
</script>
