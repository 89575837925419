import Vue from 'vue'
import VueApp from './App.vue'
import vuetify from './plugins/vuetify'
import VueRouter from 'vue-router'
import router from './router'
import store from './store'
import isMobile from './helpers/isMobile'
import autoLogin from './helpers/autologin'
import clientCode from '@/helpers/getClientCode'
import VueCryptojs from 'vue-cryptojs'
import VueCookies from 'vue-cookies'
import isNativeDevice from '@/helpers/isNativeDevie'
import { App } from '@capacitor/app'
import { VueMaskDirective } from 'v-mask'
import * as VueGoogleMaps from 'vue2-google-maps'

const token = localStorage.getItem('token')
localStorage.removeItem(token)

App.addListener('appUrlOpen', (event) => {
  // slug = /metre
  const slug = event.url.split('.ar').pop()

  if (slug) {
    router.push({
      path: slug
    })
  }
})

App.addListener('backButton', (event) => {
  router.go(-1)
})

autoLogin(() => {
  Vue.use(VueRouter)
  Vue.use(VueCookies)
  Vue.use(VueCryptojs)
  Vue.config.productionTip = false
  Vue.prototype.$isMobile = isMobile
  Vue.prototype.$clientCode = clientCode
  Vue.prototype.$isAndroidOrIos = isNativeDevice
  Vue.directive('mask', VueMaskDirective)
  Vue.use(VueGoogleMaps, {
    load: {
      key: process.env.VUE_APP_GOOGLEAPIKEY,
      libraries: 'places'
    }
  })

  new Vue({
    vuetify,
    router,
    store,
    render: h => h(VueApp)
  }).$mount('#app')
})
