<template>
  <v-container>
    <v-stepper v-model="pasoActual" :vertical="$isMobile()" v-if="$isMobile()" color="primary">
      <template v-for="(paso,n) in pasos">
        <v-stepper-header :key="`step_header_${n}`">
            <v-stepper-step
                :complete="pasoCompletado(n+1)"
                :step="n+1"
                :rules="[value => !!paso.validado]"
                :color="estado(n+1)">
                {{ paso.nombre }}
            </v-stepper-step>
        </v-stepper-header>
        <v-stepper-content :step="n+1" :key="`step_content_${n}`">
            <v-form :ref="'stepForm'" v-model="paso.validado" lazy-validation>
              <component :is="paso.componente" @importeMinimoChange="validarImporteMinimo" @compra-cancelada="errorCompra" @pedido-grabado="compraFinalizada" />
            </v-form>
            <v-btn text v-if="n+1 < ultimoPaso" color="primary" @click.prevent="validarPaso(n)" :disabled="(!paso.validado || importeInvalido)">SIGUIENTE</v-btn>
            <v-btn text v-else color="primary" @click.stop.prevent="confirmarCompra" :loading="comprando" :disabled="comprando">CONFIRMAR</v-btn>
            <v-btn text @click.prevent="$router.push({ name: 'ProductosMobile'})" v-if="pasoActual === 1">SEGUIR COMPRANDO</v-btn>
            <v-btn text @click.prevent="pasoActual = n" v-if="pasoActual != 1" :disabled="comprando">VOLVER</v-btn>
        </v-stepper-content>
      </template>
    </v-stepper>
     <v-stepper v-model="pasoActual" color="primary" v-else>
      <v-stepper-header>
            <v-stepper-step
                :step="1"
                color="primary">
                Datos para terminar tu pedido
            </v-stepper-step>
            <v-stepper-step
                :step="2"
                color="primary">
                Último paso para confirmar
            </v-stepper-step>
        </v-stepper-header>
        <v-stepper-items>
          <v-stepper-content :step="1">
              <v-form :ref="'stepForm'" v-model="pasos[0].validado" lazy-validation>
                <v-row>
                  <v-col cols="6">
                    <component :is="pasos[0].componente" @importeMinimoChange="validarImporteMinimo" :tarjetaPlana="false"/>
                  </v-col>
                  <v-col cols="6">
                    <component :is="pasos[1].componente" :tarjetaPlana="false" />
                    <v-btn text color="primary" @click.prevent="validarPaso(0)" :disabled="(!pasos[0].validado || importeInvalido)">SIGUIENTE</v-btn>
                    <v-btn text @click.prevent="$router.push({ name: 'ProductosMobile'})">SEGUIR COMPRANDO</v-btn>
                  </v-col>
                </v-row>
              </v-form>
          </v-stepper-content>
          <v-stepper-content :step="2">
              <component :is="pasos[2].componente" @compra-cancelada="errorCompra" @pedido-grabado="compraFinalizada" />
              <v-btn text color="primary" @click.stop.prevent="confirmarCompra" :loading="comprando" :disabled="comprando">CONFIRMAR</v-btn>
              <v-btn text @click.prevent="pasoActual = pasoActual - 1" v-if="pasoActual != 1" :disabled="comprando">VOLVER</v-btn>
          </v-stepper-content>
        </v-stepper-items>
    </v-stepper>
</v-container>
</template>
<script>
import { mapActions } from 'vuex'
import DatosPersonales from '../components/checkout/DatosPersonales.vue'
import FormasPagoEntrega from '../components/checkout/FormasPagoEntrega.vue'
import Resumen from '../components/checkout/Resumen.vue'
import { bus } from '@/helpers/bus.js'

export default {
  name: 'Checkout',
  components: {
    DatosPersonales,
    FormasPagoEntrega,
    Resumen
  },
  methods: {
    ...mapActions('Carrito', ['resetCarrito']),
    validarPaso (n) {
      this.pasos[n].validado = false
      const validado = this.$refs.stepForm[n] === undefined ? this.$refs.stepForm.validate() : this.$refs.stepForm[n].validate()
      if (validado) {
        this.pasos[n].validado = true
        this.pasoActual = n + 2
      }
    },
    pasoCompletado (paso) {
      return this.pasoActual > paso
    },
    estado (paso) {
      return this.pasoActual > paso ? 'green' : 'primary'
    },
    confirmarCompra () {
      this.comprando = true
      setTimeout(() => {
        bus.$emit('compraConfirmada')
      }, 2000)
    },
    errorCompra (data) {
      this.comprando = false
      if (!data.success) this.$emit('showAlert', data)
    },
    compraFinalizada (data) {
      this.comprando = false
      localStorage.setItem('mis-pedidos', JSON.stringify([parseInt(data)]))
      this.resetCarrito()
      this.$router.push({
        name: 'VerPedido',
        params: {
          id: parseInt(data)
        }
      })
    },
    validarImporteMinimo (noValido) {
      this.importeInvalido = noValido
    }
  },
  data () {
    return {
      pasos: [{
        nombre: 'Elegir forma de entrega y de pago',
        validado: false,
        componente: 'FormasPagoEntrega'
      },
      {
        nombre: 'Completar datos personales',
        validado: false,
        componente: 'DatosPersonales'
      },
      {
        nombre: 'Resumen de tu pedido',
        validado: false,
        componente: 'Resumen'
      }],
      ultimoPaso: 3,
      pasoActual: 1,
      comprando: false,
      importeInvalido: false
    }
  }
}
</script>
