<template>
<v-dialog
    transition="dialog-top-transition"
    max-width="600"
    fullscreen
    :value="isOpen"
    persistent
>
    <v-card flat>
    <v-toolbar flat>Mi pedido</v-toolbar>
    <v-card-text>
        <v-divider></v-divider>
        <v-card max-height="150px" class="overflow-y-auto" exact flat>
            <cart-details v-if="isOpen"></cart-details>
        </v-card>
        <v-divider></v-divider>
        <v-spacer></v-spacer>
        <h2>Total: ${{ totalSinCostoEnvio() }}</h2>
    </v-card-text>
    <v-card-actions class="justify-end">
        <v-btn
          :loading="comprando"
          text color="primary lighten-2"
          v-if="$route.name !== 'Checkout' && !data.NegocioCerrado"
          :disabled="getTotalItems === 0"
          @click.prevent="comprar">
          ORDENAR
        </v-btn>
        <v-btn
        text
        :disabled="comprando"
        @click="$emit('update:isOpen', false)"
        >Cerrar</v-btn>
    </v-card-actions>
    </v-card>
</v-dialog>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import CartDetails from './CartDetails.vue'
import mapToPedidoDeta from '@/helpers/mapToPedidoDeta.js'

export default {
  components: { CartDetails },
  name: 'CartDialog',
  computed: {
    ...mapGetters('Carrito', ['getTotalItems', 'getItems', 'getTotal']),
    ...mapGetters('App', ['data'])
  },
  props: {
    isOpen: {
      default: false,
      type: Boolean
    }
  },
  data () {
    return {
      comprando: false
    }
  },
  methods: {
    ...mapActions('App', ['savePedido']),
    ...mapActions('Carrito', ['resetCarrito']),
    totalSinCostoEnvio () {
      let tmpTotal = this.getTotal
      if (this.clientData && this.porcentajeAumento > 0) {
        tmpTotal += (tmpTotal * (this.porcentajeAumento / 100))
      }
      return tmpTotal
    },
    async comprar () {
      this.comprando = true
      const pedidoDTO = {
        telefono: '',
        nombre: this.$route.params.mesaId,
        direccion: 'CONSUMO EN LOCAL',
        origen: 1,
        estado: 1, // encargado
        total: this.totalSinCostoEnvio(),
        costoEnvio: 0,
        codCliente: this.$clientCode(),
        observaciones: `Mesa Nro. ${this.$route.params.mesaId}`, // this.observaciones,
        formaPago: 1, // efectivo
        formaEntrega: 4, // consumo en el local
        conCuantoPaga: 0,
        pedidosYaDescuento: 0,
        detalle: []
      }
      this.getItems.forEach(element => {
        pedidoDTO.detalle.push(mapToPedidoDeta(element))
        if (element.esCombinado || element.esEstatico) {
          element.productos.forEach((p) => {
            pedidoDTO.detalle.push(mapToPedidoDeta(p))
          })
        }
      })
      const response = await this.savePedido(pedidoDTO)
      if (response.status !== 200 || !response.data.success) {
        // error de compra
        this.comprando = false
      } else {
        localStorage.setItem('mis-pedidos', JSON.stringify([parseInt(response.data.message.trim())]))
        this.$emit('openCart')
        this.$router.push({
          name: 'VerPedido',
          params: {
            id: parseInt(response.data.message.trim())
          }
        })
        this.resetCarrito()
      }
    }
  }
}
</script>
