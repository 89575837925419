<template>
  <v-container>
    <v-row>
      <v-col cols="4"  v-for="(tienda, i) in tiendas" :key="`tienda_${i}`">
        <TiendaCard :tienda="tienda"></TiendaCard>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import TiendaCard from '../components/cards/TiendaCard.vue'

export default {
  name: 'ListaTiendasMobile',
  components: { TiendaCard },
  props: {
    tiendas: {
      type: Array
    }
  },
  mounted () {
    this.$vuetify.goTo(0)
  }
}
</script>
