<template>
  <v-card flat>
    <v-row no-gutters>
      <v-col sm="12" md="4" lg="4" xl="4">
        <v-card-text v-if="usuario">
      <h3>Datos personales</h3>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title><v-icon>mdi-account</v-icon>{{ usuario.nombre }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title><v-icon>mdi-map-marker</v-icon>{{ `${usuario.direccion} ${usuario.pisoDpto}` }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <!-- <v-list-item>
        <v-list-item-content>
          <v-list-item-title><v-icon>mdi-email</v-icon>{{ email }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item> -->
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title><v-icon>mdi-cellphone</v-icon>{{ usuario.celular }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-card-text>
      </v-col>
      <v-col sm="12" md="4" lg="4" xl="4">
         <v-card-text>
      <h3>Forma de entrega</h3>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title><v-icon>{{ iconoFormaEntrega }}</v-icon> {{ clientData.formasEnvio.filter(e => e.id === this.formaEntrega)[0].name }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-card-text>
    <v-card-text>
      <h3>Forma de pago</h3>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title><v-icon>{{ iconoFormaPago }}</v-icon> {{ clientData.formasPago.filter(e => e.id === this.formaPago)[0].name }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-card-text>
      </v-col>
      <v-col sm="12" md="4" lg="4" xl="4">
        <v-card-text>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Subtotal</v-list-item-title>
              <v-list-item-subtitle>${{ total }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="getDescuentos.hayDescuentos.length > 0">
            <v-list-item-content>
              <v-list-item-title>Descuentos</v-list-item-title>
              <!-- <v-list-item-subtitle v-for="(descuento,index) in descuentos" :key="`descuento_${index}`">
                {{ descuento }}
              </v-list-item-subtitle> -->
              <v-list-item-subtitle>${{ totalDescuento }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="porcentajeAumento > 0">
            <v-list-item-content>
              <v-list-item-title>Recargo</v-list-item-title>
              <v-list-item-subtitle>{{ porcentajeAumento }}%</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content v-if="mostrarSubtotalCostoEnvio">
              <v-list-item-title>Costo de envío</v-list-item-title>
              <v-list-item-subtitle>${{ clientData.UsarDeliveryPorZonas ? costoEnvioPorZona : clientData.CostoEnvio }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Total</v-list-item-title>
              <v-list-item-subtitle>${{ totalCompra }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card-text>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12">
        <v-textarea v-model="observaciones" outlined
        label="Para observaciones del pedido"
        single-line></v-textarea>
      </v-col>
      <v-col cols="12" v-if="aplicarCupon">
        <v-btn text color="primary" @click.prevent.stop="openCuponDialog = true" :disabled="getDescuentos.hayDescuentos.filter(d => d === 'Cupon').length > 0">
          <v-icon>mdi-ticket-percent</v-icon>
          CODIGO DE PROMO
        </v-btn>
        <cupon-dialog :open.sync="openCuponDialog" v-if="openCuponDialog"></cupon-dialog>
      </v-col>
      <v-col cols="12">
        <v-switch v-model="eligirConCuantoPaga" label="Elegir con cuanto pago"></v-switch>
        <elegir-importe-contado v-if="eligirConCuantoPaga" :total="calcularTotal" @importe-elegido="conCuantoPago = $event">
        </elegir-importe-contado>
<!--         <v-select v-show="eligirConCuantoPaga" v-model="conCuantoPago" :items="denominaciones"></v-select> -->
      </v-col>
      <v-col cols="12">
    <v-card-text>
      <h3>Detalle</h3>
      <cart-details></cart-details>
    </v-card-text>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import CartDetails from '@/components/dialogs/CartDetails.vue'
import IconosEnum from '@/helpers/iconos.js'
import { mapGetters, mapActions } from 'vuex'
import { bus } from '@/helpers/bus.js'
import mapToPedidoDeta from '@/helpers/mapToPedidoDeta.js'
import ElegirImporteContado from './ElegirImporteContado.vue'
import descuentoHelper from '@/helpers/descuentosHelper.js'
import CuponDialog from '../dialogs/CuponDialog.vue'

export default {
  components: { CartDetails, ElegirImporteContado, CuponDialog },
  name: 'Resumen',
  mounted () {
    bus.$on('nuevoCostoEnvio', (data) => {
      this.costoEnvioPorZona = data
    })
    bus.$on('onFormaPagoChangedBus', (data) => {
      const { PorcentajeAumento } = this.clientData.formasPago.filter(e => e.id === data)[0]
      this.formaPago = data
      if (PorcentajeAumento) {
        this.porcentajeAumento += PorcentajeAumento
      } else {
        this.porcentajeAumento = null
      }
    })
    bus.$on('onFormaEntregaChangedBus', (data) => {
      this.formaEntrega = data
    })
    bus.$on('onDataChangedBus', (data) => {
      this.usuario = data
    })
    bus.$on('compraConfirmada', async () => {
      // agrego esta bandera por las dudas
      // if (this.comprando) return
      // this.comprando = true
      await this.comprar()
    })
    this.aplicarCupon = descuentoHelper.existeCupon()
  },
  beforeDestroy () {
    bus.$off()
  },
  computed: {
    ...mapGetters({
      clientData: 'App/data',
      total: 'Carrito/getTotal',
      items: 'Carrito/getItems',
      whatsAppOrder: 'Carrito/getWppOrder'
    }),
    denominaciones () {
      const d = [150, 200, 500, 1000]
      return d
    },
    mostrarSubtotalCostoEnvio () {
      return (this.clientData.UsarDeliveryPorZonas || this.clientData.CostoEnvio > 0) && this.formaEntrega !== 2
    },
    totalCompra () {
      let tmpTotal = this.total
      if (this.totalDescuento > 0) {
        tmpTotal -= this.totalDescuento
      }
      if (this.clientData && this.formaEntrega === 1) {
        if (this.clientData.UsarDeliveryPorZonas) {
          tmpTotal = tmpTotal + this.costoEnvioPorZona
        } else if (this.clientData.CostoEnvio > 0) {
          tmpTotal = tmpTotal + this.clientData.CostoEnvio
        }
      }
      if (this.clientData && this.porcentajeAumento > 0) {
        tmpTotal += (tmpTotal * (this.porcentajeAumento / 100))
      }
      return tmpTotal.toFixed(2)
    },
    iconoFormaEntrega () {
      if (this.formaEntrega === 1) return IconosEnum.Delivery
      return IconosEnum.RetiraSucursal
    },
    iconoFormaPago () {
      if (this.formaPago === 2) return IconosEnum.PagoTarjeta
      return IconosEnum.PagoEfectivo
    },
    getDescuentos () {
      return descuentoHelper.calcularDescuentos(this.formaPago, this.total)
    }
  },
  methods: {
    ...mapActions('App', ['savePedido', 'savePreferenciaMercadoPago']),
    totalConCostoEnvio () {
      let tmpTotal = this.total
      if (this.clientData && this.formaEntrega === 1) {
        if (this.clientData.UsarDeliveryPorZonas) {
          tmpTotal = tmpTotal + this.costoEnvioPorZona
        } else if (this.clientData.CostoEnvio > 0) {
          tmpTotal = tmpTotal + this.clientData.CostoEnvio
        }
      }
      if (this.clientData && this.porcentajeAumento > 0) {
        tmpTotal += (tmpTotal * (this.porcentajeAumento / 100))
      }
      return tmpTotal
    },
    totalSinCostoEnvio () {
      let tmpTotal = this.total
      if (this.clientData && this.porcentajeAumento > 0) {
        tmpTotal += (tmpTotal * (this.porcentajeAumento / 100))
      }
      return tmpTotal
    },
    async comprar () {
      const total = this.totalSinCostoEnvio()
      const pedidoDTO = {
        telefono: this.usuario.celular,
        nombre: this.usuario.nombre,
        direccion: `${this.usuario.direccion} ${this.usuario.pisoDpto}`,
        origen: 1,
        estado: this.formaPago === 2 ? 8 : 1,
        total: total,
        costoEnvio: this.formaEntrega === 1 ? this.costoEnvioPorZona && this.costoEnvioPorZona > 0 ? this.costoEnvioPorZona : this.clientData.CostoEnvio : 0,
        codCliente: this.$clientCode(),
        observaciones: this.observaciones,
        formaPago: this.formaPago,
        formaEntrega: this.formaEntrega,
        conCuantoPaga: this.conCuantoPago,
        pedidosYaDescuento: this.totalDescuento,
        detalle: []
      }
      this.items.forEach(element => {
        pedidoDTO.detalle.push(mapToPedidoDeta(element))
        if (element.esCombinado || element.esEstatico) {
          element.productos.forEach((p) => {
            pedidoDTO.detalle.push(mapToPedidoDeta(p))
          })
        }
      })
      const response = await this.savePedido(pedidoDTO)
      if (response.status !== 200 || response.data.success === false) {
        this.$emit('compra-cancelada', response.data)
      } else {
        const userInfo = {
          name: this.$CryptoJS.AES.encrypt(
            this.usuario.nombre,
            process.env.VUE_APP_SECRET_PHRASE
          ).toString(),
          obs: this.$CryptoJS.AES.encrypt(
            this.observaciones,
            process.env.VUE_APP_SECRET_PHRASE
          ).toString(),
          phone: this.$CryptoJS.AES.encrypt(
            this.usuario.celular,
            process.env.VUE_APP_SECRET_PHRASE
          ).toString(),
          address: this.$CryptoJS.AES.encrypt(
            this.usuario.direccion,
            process.env.VUE_APP_SECRET_PHRASE
          ).toString()
        }
        this.$cookies.set('info', userInfo)
        // mercado-pago
        if (this.formaPago === 2) {
          // creo la preferencia
          const preference = await this.savePreferenciaMercadoPago(
            { id: response.data.message.trim(), nombre: this.usuario.nombre, total: this.totalConCostoEnvio() })
          // cargo las credenciales de mercado pago y hago click en el boton de pago para abrir el checkout
          const { credentials } = this.clientData.formasPago.filter(e => e.id === this.formaPago)[0]
          let mercadoPagoCheckout = null
          mercadoPagoCheckout = document.getElementById('mercadpagocheckout')
          if (!mercadoPagoCheckout || mercadoPagoCheckout.length === 0) {
            mercadoPagoCheckout = document.createElement('script')
            mercadoPagoCheckout.id = 'mercadpagocheckout'
            mercadoPagoCheckout.type = 'text/javascript'
            mercadoPagoCheckout.innerHTML = `
            const mp = new MercadoPago('${credentials.PublicKey}', {
                locale: 'es-AR'
            });
            const checkout = mp.checkout({
              preference: {
                  id: '${preference.data.Id}' // Indica el ID de la preferencia
              },
              render: {
                  container: '.pago-mp',
                  label: 'Pagar',
                }
            });`
            document.head.appendChild(mercadoPagoCheckout)
          }
          localStorage.setItem('mis-pedidos', JSON.stringify([parseInt(response.data.message.trim())]))
          setTimeout(() => {
            const botonPago = document.getElementById('botonmercadopago')
            botonPago.children[0].click()
          }, 3000)
        } else if (this.clientData.EnviarVentasPorWhatsapp) {
          const formaPago = this.clientData.formasPago.filter(e => e.id === this.formaPago)
          const formaEnvio = this.clientData.formasEnvio.filter(e => e.id === this.formaEntrega)
          let text = '¡Hola! Quisiera hacer el siguiente pedido: \n'
          text += `PEDIDO NRO. ${response.data.message.trim()}\n`
          text += this.whatsAppOrder
          text += `\nMis datos son:\nNOMBRE: ${this.usuario.nombre}\nDIRECCION: ${this.usuario.direccion} ${this.usuario.pisoDpto}\nCONTACTO: ${this.usuario.celular}\nOBSERVACIONES AL PEDIDO: ${this.observaciones}`
          text += `\nEl total de mi pedido es $${total}`
          if (formaPago) text += `\nForma de pago: ${formaPago[0].name.toLowerCase()}`
          if (this.conCuantoPaga) text += `\nPago con $${this.conCuantoPaga}`
          if (formaEnvio) text += `\nForma de envío: ${formaEnvio[0].name.toLowerCase()}`
          if (this.formaEntrega === 1) text += `\nEl costo de envío es $${this.costoEnvioPorZona && this.costoEnvioPorZona > 0 ? this.costoEnvioPorZona : this.clientData.costoEnvio}`
          text += '\nAguardo tu confirmación.'
          const link = document.createElement('a')
          link.href = `https://api.whatsapp.com/send?phone=+549${this.clientData.Telefono}&text=${encodeURIComponent(text)}`
          link.target = '_blank'
          link.click()
          setTimeout(() => {
            this.comprando = false
            this.$emit('pedido-grabado', response.data.message.trim())
          }, 3000)
        } else {
          this.comprando = false
          this.$emit('pedido-grabado', response.data.message.trim())
        }
      }
    }
  },
  data () {
    return {
      formaPago: null,
      formaEntrega: null,
      usuario: null,
      observaciones: '',
      conCuantoPago: null,
      costoEnvioPorZona: null,
      porcentajeAumento: null,
      eligirConCuantoPaga: false,
      comprando: false,
      totalDescuento: 0,
      descuentos: [],
      aplicarCupon: null,
      openCuponDialog: false
    }
  },
  watch: {
    getDescuentos: {
      deep: true,
      handler: function (newVal) {
        const { descuentoTotal, hayDescuentos } = newVal
        this.totalDescuento = descuentoTotal
        this.descuentos = hayDescuentos
      }
    }
  }
}
</script>
