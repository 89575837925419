<template>
  <v-autocomplete
      ref="buscador"
      v-model="busqueda"
      :items="productosCategoriasTiendas"
      :loading="isLoading"
      :search-input.sync="search"
      hide-details
      hide-selected
      prepend-inner-icon="mdi-magnify"
      item-text="Descripcion"
      item-value="Id"
      :label="label"
      solo
      :no-data-text="noDataText"
      return-object
      clearable
      single-line
      @change="buscarOpcion"
      class="ma-4"
      :filter="customFilter"
      v-show="showBuscador"
    ></v-autocomplete>
</template>
<script>
import { mapActions } from 'vuex'
import clientCode from '@/helpers/getClientCode'

export default {
  name: 'Buscador',
  computed: {
    showBuscador () {
      return this.$route.name !== 'Sucursales' && this.$route.name !== 'Checkout' && this.$route.name !== 'PageNotFound' && this.$route.name !== 'VerPedido'
    },
    productosCategoriasTiendas () {
      return this.items
    },
    label () {
      return this.labelTexts[this.$route.name]
    },
    noDataText () {
      return this.noDataTexts[this.$route.name]
    }
  },
  data () {
    return {
      items: [],
      search: null,
      busqueda: null,
      isLoading: false,
      labelTexts: {
        Tiendas: '¿Que tienda querés buscar?',
        Categorias: '¿Que querés buscar?',
        Home: '¿Que querés buscar?',
        ProductosMobile: '¿Que producto querés buscar?'
      },
      noDataTexts: {
        Tiendas: 'No se encontraron tiendas',
        Categorias: 'No se encontraron productos o categorías',
        Home: 'No se encontraron productos o categorías',
        ProductosMobile: 'No se encontraron productos'
      }
    }
  },
  methods: {
    ...mapActions('Categorias', ['getCategoriasProductos', 'searchTiendas']),
    buscarOpcion ($event) {
      if (this.busqueda.Tipo === 'Producto') {
        const code = clientCode()
        const { Id } = this.busqueda
        this.busqueda = null
        this.search = null
        this.$nextTick(() => {
          this.$refs.buscador.internalSearch = null
        })
        this.$router.push({ name: 'Producto', params: { id: Id, client: code } })
      } else if (this.busqueda.Tipo === 'Tienda') {
        const { Code } = this.busqueda
        this.busqueda = null
        this.search = null
        this.$nextTick(() => {
          this.$refs.buscador.internalSearch = null
        })
        const path = this.$isMobile() ? `/tienda/${Code}` : `/tienda/${Code}/categorias`
        this.$router.push({ path: path })
      }
    },
    customFilter (item, queryText, itemText) {
      return item
    }
  },
  watch: {
    async search (val) {
      if (val !== null) {
        switch (this.$route.name) {
          case 'Tiendas':
            this.isLoading = true
            this.items = await this.searchTiendas(val)
            this.isLoading = false
            break
          case 'Home':
          case 'ProductosMobile':
          case 'Producto':
          case 'Categorias':
            this.isLoading = true
            this.items = await this.getCategoriasProductos(val)
            this.isLoading = false
            break
          default:
            break
        }
      }
    }
  }
}
</script>
