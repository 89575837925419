import axios from '@/axios/axios-api'

const actions = {
  addItem (context, item) {
    context.commit('setItem', item)
    context.commit('calculateAmountWhenItemIsAdded', item)
  },
  removeItem (context, item) {
    context.commit('calculateAmountWhenItemIsRemoved', item)
    context.commit('eraseItem', item)
  },
  removeItemCarta (context, item) {
    context.commit('calculateAmountWhenItemIsRemoved', item)
    context.commit('eraseItemCarta', item)
  },
  resetCarrito (context, item) {
    context.commit('resetItems', item)
  },
  editItem (context, item) {
    context.commit('editItem', item)
  },
  async saveMercadoPagoPayment (context, params) {
    const clientCode = window.location.pathname.split('/')[1]
    const response = await axios.post(`api/mp/${clientCode}/process_payment`, params)
    const data = response.data
    if (data.status === 'approved') {
      return {
        pagoaprobado: true,
        pagoid: data.id
      }
    } else {
      return {
        pagoaprobado: false,
        message: data.status_detail
      }
    }
  }
}

export default actions
