<template>
  <v-card>
     <v-img
          :alt="categoria.descripcion"
          :src="categoria.image"
          @click="goToProducts(categoria)"
        >
    </v-img>
    <v-card-actions>
      <slot></slot>
    </v-card-actions>
</v-card>
</template>
<script>
export default {
  name: 'CategoriaCard',
  props: {
    categoria: {
      type: Object
    }
  },
  methods: {
    goToProducts (category) {
      this.$store.commit('Categorias/setCategoria', category)
      this.$router.push({ name: 'ProductosMobile' })
    }
  }
}
</script>
