<template>
  <v-app :style="{ background: $vuetify.theme.themes['light'].background }">
    <!-- Must have the app property -->
    <v-app-bar v-if="$route.name === 'Tiendas' || esFranquiciaApp" fixed clipped-left color="white" app>
      <v-img height="50" width="50" contain :src="clientLogo">
      </v-img>
    </v-app-bar>
    <v-app-bar color="primary" fixed clipped-left app v-else>
      <v-app-bar-nav-icon>
          <v-img height="50" width="50" contain :src="clientLogo">
          </v-img>
      </v-app-bar-nav-icon>
      <span v-if="$route.name !== 'Tiendas'" class="ml-3 mr-0 pl-0 pr-0 white--text title">{{ clientName }}</span>
      <v-spacer></v-spacer>
      <buscador v-if="!$isMobile()"></buscador>
      <v-spacer></v-spacer>
      <v-btn color="white" @click="openCart = !openCart" text>
        <v-badge
          :content="getTotalItems > 9 ? '+9' : getTotalItems"
          :value="getTotalItems > 9 ? '+9' : getTotalItems"
          color="secondary"
          overlap
          v-if="getTotalItems > 0"
        >
          <v-icon large>
            mdi-cart
          </v-icon>
        </v-badge>
      </v-btn>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer"
      app
      clipped
       color="secondary"
       v-if="categorias">
      <v-list>
        <v-list-item link>
          <v-list-item-title @click.prevent="$router.push({ name: 'Home' })">Principal</v-list-item-title>
        </v-list-item>
        <div v-for="(item,index) in categorias" :key="index">
          <v-list-item link v-if="!item.subcategorias || item.subcategorias.length == 0">
            <v-list-item-title @click.prevent="$store.commit('Categorias/setCategoria', item.id)">{{ item.descripcion }}</v-list-item-title>
          </v-list-item>
          <v-list-group v-else>
            <template v-slot:activator>
              <v-list-item-title>{{  item.descripcion }}</v-list-item-title>
            </template>
            <v-list-item v-for="(child,index) in item.subcategorias" :key="`sub_${index}`" link>
              <v-list-item-title @click.prevent="$store.commit('Categorias/setCategoria', child.id)">{{ child.descripcion }}</v-list-item-title>
            </v-list-item>
          </v-list-group>
        </div>
      </v-list>
    </v-navigation-drawer>
    <v-main>
      <v-snackbar height="300px" vertical centered elevation="2" v-model="showWarning" top color="warning">
      {{ textWarning }}
      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="showWarning = false"
        >
          OK
        </v-btn>
      </template>
    </v-snackbar>
      <cart-dialog :isOpen.sync="openCart" v-if="showCart && !$route.params.mesaId"></cart-dialog>
      <tienda-cerrada-card v-if="tiendaActual && !$route.params.mesaId"></tienda-cerrada-card>
      <QRCartDialog :isOpen.sync="openCart" v-if="showCart && $route.params.mesaId" @openCart="openCart = false"></QRCartDialog>
      <buscador v-if="$isMobile() && !$route.params.mesaId"></buscador>
      <router-view
        @drawer="drawer = $event"
        @showCart="showCart = $event"
        @showAlert="showWarning = true, textWarning = $event.message" />
    </v-main>
  </v-app>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import CartDialog from './components/dialogs/CartDialog.vue'
import Buscador from './components/Buscador.vue'
import TiendaCerradaCard from './components/cards/TiendaCerradaCard.vue'
import QRCartDialog from './components/dialogs/QRCartDialog.vue'

export default {
  components: { CartDialog, Buscador, TiendaCerradaCard, QRCartDialog },
  name: 'App',
  mounted () {
    this.showCart = false
    this.$vuetify.theme.themes.light.background = 'primary'
  },
  computed: {
    ...mapGetters('Categorias', ['categorias']),
    ...mapGetters('Carrito', ['getTotalItems']),
    ...mapGetters('App', ['data']),
    ...mapState('App', ['appLogo', 'tiendaActual']),
    clientName () {
      return this.tiendaActual ? this.tiendaActual : 'Clickaway'
    },
    clientLogo () {
      return this.appLogo ? this.appLogo : 'https://info.clickaway.app/images/clickaway-logo.png' // 'https://upload.wikimedia.org/wikipedia/commons/thumb/1/1a/Flag_of_Argentina.svg/500px-Flag_of_Argentina.svg.png'
    },
    drawer () {
      return this.$route.name === 'Categorias' && !this.$isMobile()
    },
    esFranquiciaApp () {
      return this.$route.name === 'Sucursales' && process.env.VUE_APP_LISTAR_FRANQUICIAS === 'TRUE'
    }
  },
  data () {
    return {
      isLoading: false,
      showCart: false,
      openCart: false,
      showWarning: false,
      textWarning: null
    }
  },
  watch: {
    data () {
      this.$vuetify.theme.themes.light.primary = this.data.PrimaryColor
      this.$vuetify.theme.themes.light.secondary = this.data.SecondaryColor
    }
  }
}
</script>
