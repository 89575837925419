import axios from '../axios/axios-api'

async function autoLogin (callBack) {
  const token = localStorage.getItem('token')
  if (!token) {
    const urlencoded = new URLSearchParams()
    urlencoded.append('userName', `${process.env.VUE_APP_USER}`)
    urlencoded.append('password', `${process.env.VUE_APP_PASS}`)
    urlencoded.append('grant_type', 'password')
    const response = await axios.post('/token', urlencoded)
    localStorage.setItem('token', response.data.access_token)
  }
  if (callBack) callBack()
}

export default autoLogin
