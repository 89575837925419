<template>
  <v-radio-group
    v-model="seleccion"
    mandatory
    @change="$emit('onSeleccionChange', seleccion)">
    <v-radio v-for="(o, i) in opciones"
    :label="`${o.nombre} - $ ${o.PrecioUnitario}`"
    :value="o.PrecioUnitario"
    :key="`opt_${i}`"></v-radio>
  </v-radio-group>
</template>
<script>
export default {
  name: 'Settings',
  props: {
    opciones: {
      type: Array
    }
  },
  data () {
    return {
      seleccion: null
    }
  }
}
</script>
