const state = {
  appLoading: false,
  appMessage: null,
  appLogo: '',
  data: null,
  costoEnvioPorZona: 0,
  appLogoFranquicia: null,
  tiendaActual: null,
  customerPosition: null,
  previousPage: null,
  franquiciaSeleccionada: null,
  checkout: {
    telefono: null,
    nombre: null,
    direccion: null,
    origen: 1,
    estado: null,
    total: null,
    costoEnvio: null,
    codCliente: null,
    observaciones: null,
    formaPago: null,
    formaEntrega: null,
    conCuantoPaga: null,
    detalle: []
  },
  cupon: null
}

export default state
