<template>
  <div class="pa-2">
    <v-alert
      dense
      type="warning"
      v-if="data.NegocioCerrado"
    >
      La tienda está cerrada. Podrás ver los productos pero no comprarlos.
    </v-alert>
    <v-alert
      dense
      outlined
      type="info"
      v-if="!tiendaAbierta"
    >
      La tienda está cerrada. {{ textoTiendaCerrada }}
    </v-alert>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import isOpen from '@/helpers/isOpen.js'
import getNextDate from '@/helpers/getNextDate.js'

export default {
  name: 'TiendaCerradaCard',
  computed: {
    ...mapGetters('App', ['data']),
    tiendaAbierta () {
      return isOpen(this.data.horarioRegular.dataOriginal)
    },
    textoTiendaCerrada () {
      if (!isOpen(this.data.horarioRegular.dataOriginal)) {
        return getNextDate(this.data.horarioRegular.dataOriginal)
      }
      return ''
    }
  }
}
</script>
