<template>
  <v-card elevation="0" v-if="tmpProduct" :key="`combinado_card_${tmpProduct.id}`">
      <v-list-item class="pa-0 ma-0" two-line>
        <v-list-item-action>
          <v-checkbox v-model="selected" :disabled="deshabilitarCheck" color="secondary" />
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ tmpProduct.nombre }}</v-list-item-title>
        </v-list-item-content>
        <v-list-item-content v-show="selected && $parent.llevaCantidad">
            <v-row no-gutters>
              <v-col cols="4">
                <v-btn color="secondary" @click="updateProducto(-1)" :disabled="permitirRestar" text fab small>
                  <v-icon>mdi-minus</v-icon>
                </v-btn>
              </v-col>
              <v-col cols="4">
                <v-btn id="no-background" v-text="tmpCant" @click="null" text fab :ripple="false" small></v-btn>
              </v-col>
              <v-col cols="4">
                <v-btn color="secondary" @click="updateProducto(1)" :disabled="permitirAgregar" text fab small>
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </v-col>
            </v-row>
        </v-list-item-content>
      </v-list-item>
    </v-card>
</template>

<script>
export default {
  name: 'CombinadoCard',
  computed: {
    permitirRestar () {
      return this.tmpCant <= 1
    },
    permitirAgregar () {
      return this.$parent.cantidadTotal >= this.$parent.cantidadMinima
    },
    deshabilitarCheck () {
      if (this.$parent.llevaCantidad) return this.$parent.cantidadTotal >= this.$parent.cantidadMinima && !this.selected
      else return false
    }
  },
  mounted () {
    this.tmpProduct = { ...this.producto }
    this.tmpCant = 1
  },
  data () {
    return {
      selected: false,
      tmpProduct: null,
      tmpCant: null
    }
  },
  methods: {
    updateProducto (cant) {
      this.tmpCant += cant
      this.tmpProduct.cant = this.tmpCant
      this.$emit('update-product', this.tmpProduct)
    }
  },
  props: {
    producto: {
      type: Object
    }
  },
  watch: {
    selected (newValue, oldValue) {
      this.tmpCant = 1
      if (!newValue) {
        this.$emit('remove-product', this.tmpProduct)
      } else {
        this.updateProducto(0)
      }
    }
  }
}
</script>
<style scoped>
.v-list-item__title {
  word-break: break-word !important;
}
#no-background::before {
   background-color: transparent !important;
}
</style>
