import axios from '@/axios/axios-api'
import calcularFechas from '@/helpers/FechaDesdeMenorQueFechaHasta'
import clientCode from '@/helpers/getClientCode'
import isNativeDevice from '@/helpers/isNativeDevie'
import isMobile from '@/helpers/isMobile'
import { Device } from '@capacitor/device'

const actions = {
  setAppPreviousPage (context, previousPage) {
    context.commit('setPreviousPage', previousPage)
  },
  setAppFranquiciaSeleccionada (context, franquicia) {
    context.commit('setFranquiciaSeleccionada', franquicia)
  },
  setAppLoading (context, isLoading) {
    context.commit('setLoading', isLoading)
  },
  setAppGlobalMessage (context, message) {
    context.commit('setMessage', message)
  },
  async setAppLogo (context, code) {
    // const code = clientCode()
    const response = await axios.get(`api/clientes/${code}/logo`)
    context.commit('setLogo', response.data)
  },
  async setFranchiseLogo (context, franchiseLogo) {
    context.commit('setLogo', franchiseLogo)
  },
  async setDataClient (context, code) {
    // const code = clientCode()
    const response = await axios.get(`api/clientes/${code}/data`)
    const {
      Name, CostoEnvio, FormasDeEnvio, FormasDePago, TextoEnvio, horariosRegulares,
      Telefono, SEO, EnviarVentasPorWhatsapp, UsarDeliveryPorZonas, NegocioCerrado, Descuentos,
      PrimaryColor, SecondaryColor
    } = response.data

    const formasEnvio = FormasDeEnvio.map((e) => {
      let _name = e.fEntrega_Nombre
      if (e.DefaultText && e.DefaultText !== '') _name = `${_name} (${e.DefaultText})`
      return {
        default: e.Default,
        name: _name,
        id: e.fEntrega_id,
        minimo: e.ImporteMinimoCompra
      }
    })

    const formasPago = FormasDePago.map((e) => {
      return {
        name: e.fPago_Nombre,
        id: e.fPago_id,
        default: e.Default,
        PorcentajeAumento: e.PorcentajeAumento,
        credentials: e.credentials,
        Texto: e.Texto
      }
    })
    // Primero creo el horario regular del negocio
    // para mostrar dias y horarios para dar mayor información al cliente potencial o habitual
    const diasSemana = {
      lunes: horariosRegulares.filter(hr => hr.filter(e => e.Id === 1).length > 0),
      martes: horariosRegulares.filter(hr => hr.filter(e => e.Id === 2).length > 0),
      miercoles: horariosRegulares.filter(hr => hr.filter(e => e.Id === 3).length > 0),
      jueves: horariosRegulares.filter(hr => hr.filter(e => e.Id === 4).length > 0),
      viernes: horariosRegulares.filter(hr => hr.filter(e => e.Id === 5).length > 0),
      sabado: horariosRegulares.filter(hr => hr.filter(e => e.Id === 6).length > 0),
      domingo: horariosRegulares.filter(hr => hr.filter(e => e.Id === 7).length > 0)
    }
    // con esta variable auxiliar muestro el horario que corresponde al dia
    const fechaAuxiliar = new Date(Date.now())
    // en javascript empieza a contar a partir del domingo como 0
    const diaActual = horariosRegulares.filter(hr => hr.filter(e => e.Id === (fechaAuxiliar.getDay() === 0 ? 7 : fechaAuxiliar.getDay())).length > 0)
    // infiero si el negocio está cerrado
    const negocioCerrado = diaActual.filter(e => e.filter(s => calcularFechas(fechaAuxiliar, s)).length > 0).length === 0
    // si en algun dia existe un array vacío entonces el negocio no atiende
    const horarioRegular = {
      diasSemana,
      diaActual,
      negocioCerrado,
      dataOriginal: horariosRegulares
    }

    const clientData = {
      Name,
      CostoEnvio,
      formasEnvio,
      formasPago,
      TextoEnvio,
      horarioRegular,
      Telefono,
      EnviarVentasPorWhatsapp,
      UsarDeliveryPorZonas,
      NegocioCerrado,
      Descuentos,
      PrimaryColor,
      SecondaryColor
    }
    // creo las meta etiquetas
    document.title = SEO.Title
    const description = document.createElement('meta')
    description.name = 'description'
    description.content = SEO.Description
    const keywords = document.createElement('meta')
    keywords.name = 'keywords'
    keywords.content = SEO.Keywords
    document.getElementsByTagName('head')[0].appendChild(description)
    document.getElementsByTagName('head')[0].appendChild(keywords)

    context.commit('setTiendaActual', Name)
    context.commit('setData', clientData)
  },
  setAppFranquicias (context, franquicias) {
    context.commit('setFranquicias', franquicias)
  },
  async setZonasCliente () {
    const code = clientCode()
    return await axios.get(`api/zonas/${code}`)
  },
  async setAppLogoFranquicia (context) {
    const code = clientCode()
    const response = await axios.get(`api/clientes/${code}/logo-franquicia`)
    context.commit('setLogoFranquicia', response.data)
  },
  async setFranchiseName (context, name) {
    context.commit('setTiendaActual', name)
  },
  async getTiendas (context) {
    if (context.state.customerPosition) {
      const { latitude, longitude } = context.state.customerPosition
      const response = await axios.post('api/clientes/tiendas/cercanas', {
        latitud: latitude,
        longitud: longitude
      })
      return response.data
    } else {
      const response = await axios.post('api/clientes/tiendas/todas')
      return response.data
    }
  },
  setAppCustomerPosition (context, position) {
    context.commit('setCustomerPosition', position)
  },
  async getFranquicias (context) {
    const { latitude, longitude } = context.state.customerPosition
    const franquicias = await axios.post('api/clientes/franquicias/cercanas', {
      latitud: latitude,
      longitud: longitude
    })
    return franquicias.data
  },
  async getSucursalesFranquicia (context, code) {
    if (context.state.customerPosition) {
      const { latitude, longitude } = context.state.customerPosition
      const response = await axios.post('api/clientes/franquicias/sucursales-cercanas', {
        latitud: latitude,
        longitud: longitude,
        franquicia: code
      })
      return response.data
    } else {
      const response = await axios.post(`api/clientes/franquicias/sucursales-all/${code}`)
      return response.data
    }
  },
  async savePedido (context, pedidoDTO) {
    const info = await Device.getInfo()
    const { platform } = info
    pedidoDTO.plataforma = isNativeDevice() ? platform : isMobile() ? 'Mobile' : 'Web'
    const response = await axios.post('/api/pedido/', pedidoDTO)
    return response
  },
  async getPedido (context, id) {
    const response = await axios.get(`api/pedido/Clickaway/${id}`)
    return response
  },
  async savePreferenciaMercadoPago (context, data) {
    const code = clientCode()
    const baseUrl = `${window.location.protocol}//${window.location.host}`
    const params = {
      PedidoId: data.id,
      ClientCode: code,
      BaseUrl: baseUrl,
      Total: data.total
    }
    const response = await axios.post(`api/mp/${code}/create_preference`, params)
    return response
  },
  async validarCupon (context, cupon) {
    const code = clientCode()
    const response = await axios.get(`api/clientes/${code}/validar-cupon?cupon=${cupon}`)
    return response
  },
  async setCupon (context, cupon) {
    context.commit('setCupon', cupon)
  }
}

export default actions
