<template>
  <productos-list></productos-list>
</template>
<script>
import ProductosList from '../components/CategoriasProductosList.vue'
import { mapActions } from 'vuex'

export default {
  async mounted () {
    await this.getCategorias()
  },
  components: {
    ProductosList
  },
  methods: {
    ...mapActions('Categorias', ['getCategorias'])
  }
}
</script>
