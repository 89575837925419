const getWhatsAppOrder = (state) => {
  let text = ''
  state.items.forEach(element => {
    const { nombre, observaciones, productos, cant } = element
    text += `\n ${cant}- ${nombre.toUpperCase()}`
    if (productos && productos.length > 0) {
      text += '\n'
      for (let i = 0; i < productos.length; i++) {
        const { cant, nombre } = productos[i]
        text += `   ${String.fromCodePoint(0x2705)} ${cant}- ${nombre} \n`
      }
    }
    if (observaciones) text += `   Agrego unas observaciones para el producto: ${observaciones}`
  })
  return text
}

const getters = {
  getItems: state => state.items,
  getTotal: state => state.total,
  getTotalItems: state => state.items.length,
  getWppOrder: state => getWhatsAppOrder(state)
}

export default getters
