const mapToPedidoDeta = (item) => {
  return {
    id: item.productId || item.id,
    cant: item.cant,
    price: item.precio || item.price,
    observacion: item.observaciones,
    Parent: item.Parent ? item.Parent : 'P',
    frontId: item._id
  }
}

export default mapToPedidoDeta
