const formatDate = (date) => {
  const d = new Date(date)
  let month = '' + (d.getMonth() + 1)
  let day = '' + d.getDate()
  const year = d.getFullYear()

  if (month.length < 2) month = '0' + month
  if (day.length < 2) day = '0' + day

  return [year, month, day].join('-')
}

const addOneDay = (date, hs) => {
  return new Date(`${formatDate(new Date(date.setDate(date.getDate() + 1)))}T${hs}:00`)
}

// devuelve true si la fecha A es menor que la fecha B
const calcularFechas = (fechaAux, element) => {
  const hora = fechaAux.getHours().toString().padStart(2, '0')
  const minutos = fechaAux.getMinutes().toString().padStart(2, '0')
  const calculoAux = new Date(`${formatDate(fechaAux)}T${element.HsDesde}:00`) <= new Date(`${formatDate(fechaAux)}T${hora}:${minutos}:00`) && new Date(`${formatDate(fechaAux)}T${hora}:${minutos}:00`) <= (element.HsDesde.localeCompare(element.HsHasta) > 0 ? addOneDay(fechaAux, element.HsHasta) : new Date(`${formatDate(fechaAux)}T${element.HsHasta}:00`))
  return calculoAux
}

export default calcularFechas
