<template>
  <v-container>
    <div class="text-xs-center" v-if="pedido">
      <v-alert :type="alertType">
        {{ textAlert }}
      </v-alert>
      <v-card class="mx-auto" flat>
            <v-btn
        v-if="clientData.Telefono && clientData.Telefono !== ''"
      color="green"
      dark
      bottom
      right
      fixed
      fab
      large
      @click="consultarWhatsapp"
    >
      <v-icon>mdi-whatsapp</v-icon>
    </v-btn>
      <v-list-item two-line>
      <v-list-item-content>
        <v-list-item-title class="text-h5">
          {{ pedido.Cliente }}
        </v-list-item-title>
        <v-list-item-subtitle>{{ pedido.Direccion}}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-divider></v-divider>
    <v-list-item>
      <v-list-item-avatar>
        <v-icon>mdi-store</v-icon>
      </v-list-item-avatar>
      <v-list-item-subtitle>{{ pedido.estadoActual }}</v-list-item-subtitle>
    </v-list-item>

    <v-list-item>
      <v-list-item-avatar>
        <v-icon>{{ iconoFormaEntrega }}</v-icon>
      </v-list-item-avatar>
      <v-list-item-subtitle>{{ pedido.FormaEntrega }}</v-list-item-subtitle>
    </v-list-item>

    <v-list-item two-line>
      <v-list-item-avatar>
        <v-icon>{{ iconoFormaPago }}</v-icon>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-subtitle>{{ pedido.FormaPago }}</v-list-item-subtitle>
      <v-list-item-subtitle v-if="textoFormaPago">{{ textoFormaPago }}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-divider></v-divider>
    <v-list-item two-line v-for="(product,i) in pedido.productos" :key="i">
      <v-list-item-avatar>
            <v-img :src="product.img"></v-img>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>{{ product.ProdNombre }}</v-list-item-title>
        <v-list-item-subtitle>{{ `Cantidad: ${product.Cantidad}` }}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-divider></v-divider>
    <v-list-item two-line>
      <v-list-item-content>
        <v-list-item-title class="text-h5">
          Subtotal:
        </v-list-item-title>
        <v-list-item-subtitle>$ {{ pedido.Total }}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-list-item two-line v-if="pedido.PedidosYaDescuento > 0">
      <v-list-item-content>
        <v-list-item-title class="text-h5">
          Descuentos:
        </v-list-item-title>
        <v-list-item-subtitle>- $ {{ pedido.PedidosYaDescuento }}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-list-item two-line v-if="pedido.CostoEnvio > 0">
      <v-list-item-content>
        <v-list-item-title class="text-h5">
          Costo de envío:
        </v-list-item-title>
        <v-list-item-subtitle>$ {{ pedido.CostoEnvio }}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-divider></v-divider>
    <v-list-item two-line>
      <v-list-item-content>
        <v-list-item-title class="text-h5">
          Total:
        </v-list-item-title>
        <v-list-item-subtitle>$ {{ total }}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-list-item two-line v-if="pedido.ConCuantoPaga > 0">
      <v-list-item-content>
        <v-list-item-title class="text-h5">
          Con cuanto paga:
        </v-list-item-title>
        <v-list-item-subtitle>$ {{ pedido.ConCuantoPaga }}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-btn color="primary" @click="$router.go(0)">VER ESTADO</v-btn>
      </v-card>
    </div>
  </v-container>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import IconosEnum from '@/helpers/iconos.js'

export default {
  name: 'EstadoPedido',
  computed: {
    iconoFormaEntrega () {
      if (this.pedido.fEntrega_id === 1) return IconosEnum.Delivery
      return IconosEnum.RetiraSucursal
    },
    total () {
      return this.pedido.Total - this.pedido.PedidosYaDescuento + this.pedido.CostoEnvio
    },
    textoFormaPago () {
      if (this.clientData && this.clientData.formasPago) {
        const formaPago = this.clientData.formasPago.filter(e => e.id === this.pedido.fPago_id)
        return formaPago[0].Texto
      }
      return null
    },
    iconoFormaPago () {
      if (this.pedido.fPago_id === 2) return IconosEnum.PagoTarjeta
      return IconosEnum.PagoEfectivo
    },
    alertType () {
      if (this.pedido.estadoActual === 'Encargado' || this.pedido.estadoActual === 'En Camino' || this.pedido.estadoActual === 'Confirmado' || this.pedido.estadoActual === 'En Preparacion') return 'success'
      if (this.pedido.estadoActual === 'Pendiente de pago') return 'warning'
      return 'error'
    },
    textAlert () {
      if (this.pedido.estadoActual === 'Encargado' || this.pedido.estadoActual === 'En Camino' || this.pedido.estadoActual === 'Confirmado' || this.pedido.estadoActual === 'En Preparacion') return `Pedido #${this.pedido.codigo} recibido con éxito`
      if (this.pedido.estadoActual === 'Pendiente de pago') return `Pedido #${this.pedido.codigo} pendiente de pago`
      return 'Ha habido un error al generar el pedido'
    },
    ...mapGetters({
      clientData: 'App/data'
    })
  },
  methods: {
    ...mapActions({
      setData: 'App/setDataClient',
      getPedido: 'App/getPedido'
    }),
    consultarWhatsapp () {
      window.open(`https://api.whatsapp.com/send?phone=+549${this.clientData.Telefono}&text=Hola%21%20Quisiera%20realizar%20una%20consulta`, '_blank')
    }
  },
  async mounted () {
    const pedidoGrabado = localStorage.getItem('mis-pedidos')
    if (pedidoGrabado) {
      if (JSON.parse(pedidoGrabado)[0] !== parseInt(this.$route.params.id)) {
        this.$router.push({ name: 'ErrorPage' })
      } else {
        const request = await this.getPedido(this.$route.params.id)
        this.pedido = request.data
        await this.setData(request.data.Tienda)
      }
    }
  },
  data () {
    return {
      pedido: null
    }
  }
}
</script>
