const getters = {
  appIsLoading: state => state.appLoading,
  appLogo: state => state.appLogo,
  data: state => state.data,
  franquicias: state => state.franquicias,
  costoEnvioPorZona: state => state.costoEnvioPorZona,
  appLogoFranquicia: state => state.appLogoFranquicia,
  appCustomerPosition: state => state.customerPosition,
  appPreviousPage: state => state.previousPage,
  appFranquiciaSeleccionada: state => state.franquiciaSeleccionada,
  appCupon: state => state.cupon
}

export default getters
