import Vue from 'vue'
import Vuex from 'vuex'
import Categorias from './categorias'
import Productos from './productos'
import App from './app'
import Carrito from './carrito'
Vue.use(Vuex)

const modules = {
  Categorias,
  Productos,
  App,
  Carrito
}

export default new Vuex.Store({
  modules
})
