<template>
  <v-container>
    <div v-if="tmpProduct">
      <go-back :routeName="previousPage"></go-back>
      <v-row>
        <v-col :cols="colProducto">
          <v-card
        class="mx-auto"
        :flat="!$isMobile()"
        >
        <v-img
        :src="tmpProduct.detalle.srcImg"
        height="350px"
        ></v-img>

        <v-card-title>{{ tmpProduct.nombre }}</v-card-title>

        <v-card-text>
          <div v-if="tmpProduct.Descuentos.length > 0 || tmpProduct.GrupoDescuento">
            <div><span class="my-1 display-1">$ {{ descuento }}&nbsp;</span><del class="title" >${{tmpProduct.PrecioUnitario}}</del></div>
          </div>
          <div v-else>
            <div class="my-1 text-subtitle-1">$ {{ tmpProduct.PrecioUnitario }}</div>
          </div>
          <div v-if="tmpProduct.detalle.Descripcion && tmpProduct.detalle.Descripcion !== ''">
            {{ tmpProduct.detalle.Descripcion }}
          </div>
        </v-card-text>

        <template v-if="tmpProduct.esSeleccion">
          <v-divider class="mx-4"></v-divider>
          <v-card-title>Presentaciones</v-card-title>
          <v-card-text>
            <seleccion :opciones="tmpProduct.productos"
            @onSeleccionChange="customProduct.totalPrice = $event"></seleccion>
          </v-card-text>
        </template>

        <template v-if="tmpProduct.esCombinado">
          <v-divider class="mx-4"></v-divider>
          <!-- DIBUJO LOS RADIOBUTTONS -->
          <div
            v-for="(grupoOpcional, i) in tmpProduct.radioButtons"
            :key="`grupoOpcional_${i}`">
            <v-card-title>{{ grupoOpcional.nombre }}</v-card-title>
            <v-card-text>
              <opcionales :opcionales="grupoOpcional.productos"
              @removeItem="removeCustomItem($event)"
              :llevaImporte="grupoOpcional.LlevaImporte"
              @addItem="addCustomItem($event)"
              @restarImporte="customProduct.totalPrice -= $event"
              @sumarImporte="customProduct.totalPrice += $event">
              </opcionales>
            </v-card-text>
          </div>
          <!-- DIBUJO LOS CHECKBOXS -->
          <div v-for="(grupoCheckbox, i) in tmpProduct.checkBox"
            :key="`grupoCheckbox_${i}`">
            <v-card-title>{{ grupoCheckbox.nombre }}</v-card-title>
            <v-card-subtitle v-if="grupoCheckbox.CantMinima">Cantidad máxima a elegir: {{ grupoCheckbox.CantMinima }} unidades.</v-card-subtitle>
            <v-card-text>
              <combinados :productos="grupoCheckbox.productos"
              :cantidadMaxima="grupoCheckbox.CantMaxima"
              :cantidadMinima="grupoCheckbox.CantMinima"
              :llevaCantidad="grupoCheckbox.LlevaCantidad"
              :llevaImporte="grupoCheckbox.LlevaImporte"
              @updateCustomItem="addCustomItems"
              :grupo="grupoCheckbox.GuidProducto"
              @restarImporte="customProduct.totalPrice -= $event"
              @sumarImporte="customProduct.totalPrice += $event">
              </combinados>
            </v-card-text>
          </div>
          <!-- DIBUJO LOS ESTATICOS -->
          <estaticos :estaticos="tmpProduct.estaticos"></estaticos>
        </template>
        <v-card-text>
          <v-textarea v-model="customProduct.obs" label="Observaciones" outlined height="75"></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="primary lighten-2"
            text
            @click="buy"
            v-if="validarRadioButtons && validarCantidadCheckbox"
          >
            AGREGAR
          </v-btn>
        </v-card-actions>
      </v-card>
        </v-col>
        <v-col v-if="!$isMobile()" :cols="colProducto">
          <h4>Mi pedido</h4>
          <cart-details></cart-details>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <div class="mx-auto mt-4 display-1">
            Encontrá también en {{ tmpProduct.detalle.categoria }}
          </div>
        </v-col>
        <v-col cols="12">
          <similares :id="tmpProduct.id" :catId="tmpProduct.detalle.categoriaId"></similares>
        </v-col>
      </v-row>
    </div>
    <div v-else>
      <v-skeleton-loader type="image"></v-skeleton-loader>
    </div>
  </v-container>
</template>
<script>
import { mapActions } from 'vuex'
import GoBack from '../components/GoBack.vue'
import Estaticos from '../components/products/Estaticos.vue'
import Opcionales from '../components/products/Opcionales.vue'
import Seleccion from '../components/products/Seleccion.vue'
import Combinados from '../components/products/Combinados.vue'
import Similares from '../components/products/Similares.vue'
import CartDetails from '../components/dialogs/CartDetails.vue'
import descuentoHelper from '@/helpers/descuentosHelper.js'

export default {
  components: { GoBack, Seleccion, Opcionales, Estaticos, Combinados, Similares, CartDetails },
  name: 'Producto',
  computed: {
    descuento () {
      return descuentoHelper.precioConDescuento(this.tmpProduct)
    },
    colProducto () {
      return this.$isMobile() ? '12' : '6'
    },
    validarRadioButtons () {
      // no existen los radiobutons para validar
      if (!this.tmpProduct.radioButtons) return true
      // o tal vez exista pero es una lista vacía
      if (this.tmpProduct.radioButtons.length === 0) return true
      // voy a guardar en un array un valor booleano true, si al menos hay un producto seleccionado por cada grupo
      const arrayBooleano = []
      // si no tengo que validar que haya seleccionado alguno de ellos
      const radioButtonsGroups = this.tmpProduct.radioButtons.map(e => e.productos.map(f => f.id))
      const productosSeleccionados = this.customProduct.items.map(e => e.id)
      for (const index in radioButtonsGroups) {
        const radioButton = radioButtonsGroups[index]
        let validado = false
        for (const product in radioButton) {
          // la bandera cambia si al menos uno fue seleccionado de cada grupo
          if (productosSeleccionados.includes(radioButton[product])) {
            validado = true
          }
        }
        arrayBooleano.push(validado)
      }
      return arrayBooleano.reduce((a, b) => a && b)
    },
    validarCantidadCheckbox () {
      if (!this.customProduct) return false
      let validado = true
      const itemsConGrupo = this.customProduct.items.filter(i => i.grupo !== undefined)
      if (this.customProduct.cantidadMinimaPorGrupo.length === 0 && itemsConGrupo.length === 0) return validado
      // por cada grupo me fijo que tenga la cantidad minima requerida
      this.customProduct.cantidadMinimaPorGrupo.forEach(e => {
        // busco items para el grupo actual
        if (itemsConGrupo.filter(i => i.grupo === e.name).length > 0) {
          const cantSeleccionada = this.customProduct.items.filter(i => i.grupo === e.name).reduce((a, b) => a + b.cant, 0)
          const cantRequerida = e.quantity
          if (cantSeleccionada === cantRequerida) {
            validado = true && validado
          } else {
            validado = false && validado
          }
        } else {
          validado = false
        }
      })
      return validado
    }
  },
  methods: {
    ...mapActions('Productos', ['getProductById']),
    ...mapActions('Carrito', ['addItem', 'removeItem']),
    buy () {
      this.addItem(this.customProduct)
      const routeName = this.mesaId ? 'MesaQRProductos' : 'ProductosMobile'
      this.$router.push({ name: routeName })
    },
    async getProduct () {
      this.tmpProduct = await this.getProductById(this.id)
      this.customProduct.id = this.tmpProduct.id
      this.customProduct.nombre = this.tmpProduct.nombre
      this.customProduct.totalPrice = this.tmpProduct.PrecioUnitario * this.tmpProduct.cant
      this.customProduct.tipo = this.tmpProduct.tipo
      this.customProduct.esSeleccion = this.tmpProduct.esSeleccion
      this.customProduct.esCombinadoCombo = this.tmpProduct.esCombinado
      this.customProduct.detalle.srcImg = this.tmpProduct.detalle.srcImg
      this.customProduct.cant = 1
      if (this.tmpProduct.esCombinado) {
        const gruposQueLlevanCantidad = this.tmpProduct.checkBox.filter((chk) => chk.CantMinima > 0)
        this.customProduct.cantidadMinimaPorGrupo = gruposQueLlevanCantidad.map((chk) => { return { name: chk.GuidProducto, quantity: chk.CantMinima } })
      }
      this.appendEstaticosToItems()
      this.customProduct.detalle.categoriaId = this.tmpProduct.detalle.categoriaId
      this.customProduct.Descuentos = this.tmpProduct.Descuentos
      this.customProduct.GrupoDescuento = this.tmpProduct.GrupoDescuento
    },
    removeCustomItem (item, callBack) {
      if (this.customProduct.items.filter(e => e.id === item.id).length > 0) {
        this.customProduct.items = this.customProduct.items.filter(e => e.id !== item.id)
      }
      if (callBack) callBack()
    },
    addCustomItem (item) {
      // rehuso esta función para eliminar el producto, si ya existe, por el actualizado
      this.removeCustomItem(item, () => {
        this.customProduct.items.push(item)
      })
    },
    addCustomItems (payload) {
      // filtro por guid del grupo y si existen, elimino esos elementos
      if (this.customProduct.items.filter(e => e.grupo === payload.grupo).length > 0) {
        // el undefined es para los items que no tienen grupos
        this.customProduct.items = this.customProduct.items.filter(e => e.grupo !== payload.grupo || e.grupo === undefined)
      }
      this.customProduct.items = this.customProduct.items.concat(payload.items)
    },
    appendEstaticosToItems () {
      if (this.tmpProduct.estaticos && this.tmpProduct.estaticos.length > 0) {
        for (let i = 0; i < this.tmpProduct.estaticos.length; i++) {
          const idsEstaticos = this.tmpProduct.estaticos[i].productos.map((e) => e.id)
          if (this.customProduct.items.filter((e) => idsEstaticos.includes(e.id))?.length === 0) {
            this.customProduct.items = this.customProduct.items.concat(this.tmpProduct.estaticos[i].productos.map((e) => {
              return {
                id: e.id,
                nombre: e.nombre,
                esCombinado: false,
                esSimple: false,
                esSeleccion: false,
                esRadioButton: false,
                esCheckBox: false,
                esEstatico: true,
                cant: e.CantMinima,
                PrecioUnitario: e.PrecioUnitario,
                habilitado: e.habilitado
              }
            }))
          }
        }
      }
    }
  },
  props: ['id', 'mesaId'],
  async mounted () {
    await this.getProduct()
  },
  data () {
    const previousPage = this.mesaId ? 'MesaQRProductos' : 'ProductosMobile'
    console.log(previousPage)
    return {
      tmpProduct: null,
      customProduct: {
        id: 0,
        tipo: null,
        cant: null,
        nombre: '',
        items: [],
        esCombinadoCombo: false,
        esSeleccion: false,
        totalPrice: 0,
        cantidadMinimaPorGrupo: [],
        detalle: {
          categoriaId: null,
          srcImg: null
        },
        obs: null,
        Descuentos: [],
        GrupoDescuentos: []
      },
      previousPage: {
        name: previousPage
      }
    }
  },
  watch: {
    async id () {
      this.customProduct = {
        id: 0,
        tipo: null,
        cant: null,
        nombre: '',
        items: [],
        esCombinadoCombo: false,
        esSeleccion: false,
        totalPrice: 0,
        cantidadMinimaPorGrupo: [],
        detalle: {
          categoriaId: null,
          srcImg: null
        },
        obs: null
      }
      this.tmpProduct = null
      await this.getProduct()
    }
  }
}
</script>
