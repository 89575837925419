<template>
  <v-container>
    <CategoriasHomeMobile :mesa-id="mesaId"></CategoriasHomeMobile>
  </v-container>
</template>
<script>
import CategoriasHomeMobile from '../components/mobile/CategoriasHomeMobile.vue'

export default {
  name: 'MesaQR',
  components: {
    CategoriasHomeMobile
  },
  data () {
    return {
      mesaId: null
    }
  },
  mounted () {
    this.mesaId = this.$route.params.mesaId
    this.$emit('showCart', true)
    localStorage.setItem('mesaId', this.$route.params.mesaId)
  }
}
</script>
