const mutations = {
  setCategorias (state, val) {
    state.categorias = val
  },
  setCategoria (state, val) {
    state.currentCategoria = val
  }
}

export default mutations
