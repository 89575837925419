<template>
  <v-radio-group v-model="selection">
      <v-radio
        v-for="(opcion, i) in opcionales"
        :key="`opcional_${i}`"
        :label="opcion.nombre"
        :value="opcion"
      ></v-radio>
    </v-radio-group>
</template>
<script>
export default {
  name: 'Opcionales',
  props: {
    opcionales: {
      type: Array
    },
    llevaImporte: {
      type: Boolean
    }
  },
  mounted () {
    this.selection = this.opcionales[0]
  },
  data () {
    return {
      selection: null
    }
  },
  watch: {
    selection (newValue, oldValue) {
      if (oldValue) this.$emit('removeItem', oldValue)
      newValue.cant = 1
      this.$emit('addItem', newValue)
      if (this.llevaImporte) {
        if (oldValue) this.$emit('restarImporte', oldValue.PrecioUnitario)
        this.$emit('sumarImporte', newValue.PrecioUnitario)
      }
    }
  }
}
</script>
