<template>
  <v-card :flat="tarjetaPlana" :exact="tarjetaPlana">
    <v-card-title v-if="!tarjetaPlana">
      Datos personales
    </v-card-title>
    <v-card-text>
      <v-text-field
        label="Nombre"
        prepend-inner-icon="mdi-account"
        v-model="usuario.nombre"
        :rules="[
            () => !!usuario.nombre || 'Falta completar tu nombre',
            () => !!usuario.nombre && usuario.nombre.length <= 25 || 'Completá tu nombre hasta 25 caracteres'
          ]"
        outlined>
      </v-text-field>
      <v-row>
        <v-col cols="9">
          <v-text-field
          label="Domicilio"
          prepend-inner-icon="mdi-map-marker"
          id="direccion"
          ref="direccion"
          @click:prepend-inner="onClickMapMarker"
          v-model="usuario.direccion"
          :disabled="deshabilitarDomicilio"
          :readonly="clientData.UsarDeliveryPorZonas"
          :rules="[
              () => !!usuario.direccion || 'Falta completar tu domicilio',
              () => !domicilioFueraDeLasZonas || 'No hacemos entregas en tu zona.'
            ]"
          outlined>
          </v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field
          label="Piso / Dpto."
          id="pisoDpto"
          ref="pisoDpto"
          v-model="usuario.pisoDpto"
          :disabled="deshabilitarDomicilio"
          outlined>
        </v-text-field>
        </v-col>
      </v-row>
      <!-- <v-text-field label="Correo" prepend-inner-icon="mdi-email" v-model="email" clearable outlined></v-text-field> -->
      <v-text-field
        label="Celular"
        :rules="[
            () => !!usuario.celular || 'Falta completar tu celular',
            () => !!usuario.celular && usuario.celular.length == 10 || 'Formato incorrecto',
            () => !!usuario.celular && !isNaN(usuario.celular) || 'Formato incorrecto'
          ]"
        prepend-inner-icon="mdi-cellphone"
        v-model="usuario.celular"
        outlined></v-text-field>
        <p v-if="clientData.UsarDeliveryPorZonas">Haga click en <v-icon color="primary">mdi-map-marker</v-icon> para seleccionar otro domicilio.</p>
    </v-card-text>
    <v-dialog ref="mapDialog" v-if="canOpen" v-model="openMap"
        transition="dialog-bottom-transition"
        max-width="450px" persistent :fullscreen="$isMobile()">
      <GoogleMapSelector v-if="canOpen && openMap" @cerrar-map-selector="openMap = false" @fuera-rango-entrega="domicilioFueraDeLasZonas = $event"/>
    </v-dialog>
    <v-slot></v-slot>
  </v-card>
</template>
<script>
import { mapGetters } from 'vuex'
import { bus } from '@/helpers/bus.js'
import GoogleMapSelector from './GoogleMapSelector.vue'

export default {
  name: 'DatosPersonales',
  components: {
    GoogleMapSelector
  },
  computed: {
    ...mapGetters({
      clientData: 'App/data'
    }),
    canOpen () {
      return this.clientData.UsarDeliveryPorZonas && this.formaEntrega !== 2
    }
  },
  methods: {
    onClickMapMarker () {
      if (this.clientData.UsarDeliveryPorZonas && this.formaEntrega !== 2) {
        this.openMap = true
        this.usuario.direccion = null
        this.domicilioFueraDeLasZonas = true
      }
    }
  },
  props: {
    tarjetaPlana: {
      type: Boolean,
      default: true
    }
  },
  async created () {
    bus.$on('onFormaEntregaChangedBus', (data) => {
      if (data === 2) {
        this.formaEntrega = 2
        this.ultimaDireccion = this.usuario.direccion
        this.usuario.direccion = 'RETIRA EN SUCURSAL'
        this.deshabilitarDomicilio = true
        this.domicilioFueraDeLasZonas = false
        this.openMap = false
      } else {
        this.formaEntrega = -1
        this.usuario.direccion = this.clientData.UsarDeliveryPorZonas ? null : this.ultimaDireccion
        this.deshabilitarDomicilio = false
        this.domicilioFueraDeLasZonas = this.clientData.UsarDeliveryPorZonas && this.formaEntrega !== 2
        this.openMap = true
      }
    })
    bus.$on('onDomicilioGoogleMapSelectorChange', (data) => {
      this.usuario.direccion = data
    })
    if (this.$cookies.isKey('info')) {
      const info = this.$cookies.get('info')
      this.usuario.nombre = this.$CryptoJS.AES.decrypt(
        info.name,
        process.env.VUE_APP_SECRET_PHRASE
      ).toString(this.CryptoJS.enc.Utf8)
      this.usuario.direccion = this.$CryptoJS.AES.decrypt(
        info.address,
        process.env.VUE_APP_SECRET_PHRASE
      ).toString(this.CryptoJS.enc.Utf8)
      this.usuario.celular = this.$CryptoJS.AES.decrypt(
        info.phone,
        process.env.VUE_APP_SECRET_PHRASE
      ).toString(this.CryptoJS.enc.Utf8)
      this.observaciones = this.$CryptoJS.AES.decrypt(
        info.obs,
        process.env.VUE_APP_SECRET_PHRASE
      ).toString(this.CryptoJS.enc.Utf8)
    }
  },
  data () {
    return {
      ultimaDireccion: null,
      usuario: {
        nombre: null,
        celular: null,
        direccion: null,
        pisoDpto: ''
      },
      openMap: false,
      domicilioFueraDeLasZonas: null,
      deshabilitarDomicilio: false,
      formaEntrega: -1
    }
  },
  watch: {
    'usuario.nombre' () {
      bus.$emit('onDataChangedBus', this.usuario)
    },
    'usuario.direccion' () {
      bus.$emit('onDataChangedBus', this.usuario)
    },
    'usuario.celular' () {
      bus.$emit('onDataChangedBus', this.usuario)
    },
    'usuario.pisoDpto' () {
      bus.$emit('onDataChangedBus', this.usuario)
    }
  }
}
</script>
