const date = new Date()
// const dias = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sabado']
// const dia = dias[date.getDay()]
const hour = (date.getHours() * 100) + date.getMinutes()

const enRangoHorario = (horario) => {
  const { HsDesde, HsHasta } = horario
  const desde = parseInt(HsDesde.replace(':', ''))
  let hasta = parseInt(HsHasta.replace(':', ''))
  // por ejemplo de 20:00 a 00:15, donde en números, la hora hasta es menor a la hora desde
  if (hasta < desde) {
    hasta = 2359
  }
  // si la hora actual está dentro del rango del primer horario
  if (desde <= hour && hasta >= hour) {
    return true
  }
  return false
}

const isOpen = (horarios) => {
  const day = date.getDay() === 0 ? 7 : date.getDay()
  // si no tienen horarios configurados, o viene un array vacio,  esta abierto por defecto
  if (!horarios || horarios.length === 0) return true
  const horariosFitrados = horarios.filter(e => e.filter(i => i.Id === day).length > 0)[0]
  if (horariosFitrados === undefined || horariosFitrados.length === 0) return false
  const result = enRangoHorario(horariosFitrados[0])
  // si tiene un segundo horario de atencion, pregunto si la hora actual está dentro del rango
  if (!result && horariosFitrados.length > 1) {
    return enRangoHorario(horariosFitrados[1])
  }
  // siempre devuelvo false si no entra en ninguno de los if anteriores
  return result
}

export default isOpen
