<template>
  <v-row class="mb-2">
    <v-col>
      <v-icon @click.stop.prevent="$router.push(routeName)">mdi-arrow-left</v-icon>
    </v-col>
  </v-row>
</template>
<script>
export default {
  props: ['routeName'],
  name: 'GoBack'
}
</script>
