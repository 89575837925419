const date = new Date()
const dias = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sabado']

const getNextDate = (horarios) => {
  // me fijo si hay horarios para el dia de hoy
  return devolverSiguienteDiaAbierto(horarios)
  /* const day = (date.getDay() === 0 ? 7 : date.getDay())
  const horariosDelDia = horarios.filter(e => e.filter(i => i.Id === day).length > 0)[0]
  if (horariosDelDia.length > 0) {
    const tmp = horariosDelDia.filter(e => e.HsDesde > `${date.getHours()}:${date.getMinutes()}`)
    if (tmp.length > 0) {
      return `Abre hoy a las ${tmp[0].HsDesde}.`
    } else {
      // me fijo en el siguiente horario disponible
      for (var k = 0; k < 7; k++) {
        const nextDay = dias[k]
        const siguienteHorario = horarios.filter(e => e.filter(i => i.Id === (k === 0 ? 7 : k)))[0]
        if (siguienteHorario.length > 0) {
          const menorHoraDesde = Math.min(...siguienteHorario.map(e => parseInt(e.HsDesde.replace(':', ''))))
          return `Abre el día ${nextDay} a las
          ${siguienteHorario.filter(i => parseInt(i.HsDesde.replace(':', '')) === menorHoraDesde)[0].HsDesde}.`
        }
      }
    }
  } else {
    // me fijo en el siguiente horario disponible
    for (var i = 0; i < 7; i++) {
      const nextDay = dias[i]
      const siguienteHorario = horarios.filter(e => e.filter(i => i.Id === (i === 0 ? 7 : i)))[0]
      if (siguienteHorario.length > 0) {
        const menorHoraDesde = Math.min(...siguienteHorario.map(e => parseInt(e.HsDesde.replace(':', ''))))
        return `Abre el día ${nextDay} a las
        ${siguienteHorario.filter(i => parseInt(i.HsDesde.replace(':', '')) === menorHoraDesde)[0].HsDesde}.`
      }
    }
  } */
}

const filtrarHoraDesde = (hsDesde) => {
  const hora = date.getHours()
  return hsDesde >= hora
}

const ordenarPorHsDesde = (a, b) => {
  // a is less than b by some ordering criterion
  if (convertirEntero(a.HsDesde) < convertirEntero(b.HsDesde)) {
    return -1
  }
  // a is greater than b by the ordering criterion
  if (convertirEntero(a.HsDesde) > convertirEntero(b.HsDesde)) {
    return 1
  }
  return 0
}

const convertirEntero = (hsDesde) => {
  return parseInt(hsDesde.split(':')[0])
}

const devolverSiguienteDiaAbierto = (horarios) => {
  const day = (date.getDay() === 0 ? 7 : date.getDay())
  const horariosDelDia = horarios.filter(e => e.filter(i => i.Id === day).length > 0)[0]
  if (horariosDelDia === undefined) return ''
  const tmp = horariosDelDia.filter(e => filtrarHoraDesde(convertirEntero(e.HsDesde)))
  tmp.sort(ordenarPorHsDesde)
  if (tmp.length > 0) {
    return `Abre hoy a las ${tmp[0].HsDesde}.`
  } else {
    // me fijo en el siguiente horario disponible
    let nextDay = day
    for (var k = 0; k < 7; k++) {
      // si el dia es mayor a 7 (domingo), le asigno 1
      if (nextDay > 7) {
        nextDay = 1
      } else {
        nextDay++
      }
      const nextDayName = dias[nextDay]
      const siguienteHorario = horarios.filter(e => e.filter(i => i.Id === nextDay).length > 0)[0]
      if (siguienteHorario.length > 0) {
        const menorHoraDesde = Math.min(...siguienteHorario.map(e => parseInt(e.HsDesde.replace(':', ''))))
        return `Abre el día ${nextDayName} a las 
        ${siguienteHorario.filter(i => parseInt(i.HsDesde.replace(':', '')) === menorHoraDesde)[0].HsDesde}.`
      }
    }
  }
}

export default getNextDate
