<template>
  <v-card
    class="mx-auto mb-4"
    max-width="344"
    outlined
  >
    <v-list-item three-line>
      <v-list-item-avatar
        tile
        size="80"
        color="grey"
      >
      <v-img
          :alt="tmpTienda.Name"
          :src="tmpTienda.Img"
          aspect-ratio="1"
        ></v-img>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title class="text-wrap text-h5 mb-1">
          {{ tmpTienda.Name }}
        </v-list-item-title>
        <v-list-item-subtitle v-if="tmpTienda.Address">{{ tmpTienda.Address }}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-card-text>
      <!-- Cerrado hace referencia al bit en la tabla clientes, mientras que la funcion es en relacion al horario de atencion -->
      <v-alert v-show="!tiendaAbierta(tmpTienda.horarios) && !tmpTienda.Cerrado"
        type="info"
        outlined
        icon="mdi-lock"
        class="text-center"
        dense>{{ textoTiendaCerrada(tmpTienda.horarios) }}</v-alert>
      <v-alert v-show="tmpTienda.Cerrado"
      type="warning"
      outlined
      icon="mdi-lock"
      class="text-center"
      dense>TIENDA CERRADA</v-alert>
      <p class="text-body-1" v-if="tmpTienda.horarios && tmpTienda.horarios.length > 0">
        <v-icon color="primary">
          mdi-clock-time-three-outline
        </v-icon>
        {{ horarioActual(tmpTienda.horarios) }}
      </p>
      <p class="text-body-1" v-if="tmpTienda.Distancia !== -1">
        <v-icon color="primary">
          mdi-map-marker-distance
        </v-icon>
        {{ `${Math.floor(tmpTienda.Distancia)} mts.` }}
      </p>
    </v-card-text>
    <v-card-actions>
      <v-btn
            color="primary lighten-2"
            text
            @click.prevent="onClickTienda(tmpTienda.Code,tmpTienda.EsFranquicia)"
            :loading="tiendaSeleccionada"
          >
        {{ tmpTienda.EsFranquicia ? 'VER SUCURSALES' : 'VER CARTA' }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import isOpen from '@/helpers/isOpen.js'
import schedules from '@/helpers/schedules.js'
import getNextDate from '@/helpers/getNextDate.js'

export default {
  name: 'TiendaCard',
  methods: {
    textoTiendaCerrada (horarios) {
      if (!isOpen(horarios)) {
        const resp = getNextDate(horarios)
        return resp === '' ? 'TIENDA CERRADA.' : `Cerrado.${resp}`
      }
    },
    tiendaAbierta (horarios) {
      return isOpen(horarios)
    },
    horarioActual (horarios) {
      return schedules(horarios)
    },
    onClickTienda (code, esFranquicia) {
      this.tiendaSeleccionada = true
      if (esFranquicia) {
        this.$router.push({ name: 'Sucursales', params: { id: code } })
      } else {
        this.$router.push({ name: 'Home', params: { client: code } })
      }
    }
  },
  props: {
    tienda: {
      type: Object
    }
  },
  created () {
    this.tmpTienda = { ...this.tienda }
  },
  data () {
    return {
      tiendaSeleccionada: false,
      tmpTienda: {}
    }
  }
}
</script>
