import axios from '@/axios/axios-api'
import clientCode from '@/helpers/getClientCode'

const actions = {
  async getCategorias (context) {
    const code = clientCode()
    const response = await axios.get(`api/categorias/${code}`)
    context.commit('setCategorias', response.data)
  },
  async getCategoriasProductos (context, search) {
    const code = clientCode()
    const response = await axios.get(`api/busqueda/${code}?search=${search}`)
    return response.data
  },
  // para no crear un store de "tiendas"
  async searchTiendas (context, search) {
    const response = await axios.get(`api/busqueda/tiendas?search=${search}`)
    return response.data
  }
}

export default actions
