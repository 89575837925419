<template>
  <v-container>
    <v-alert type="warning" outlined v-if="accesoDenegadoUsuario">
      <p>Has denegado el acceso a tu ubicacion. No podremos mostrarte las tiendas mas cercanas.</p>
    </v-alert>
    <div v-if="$isMobile()">
      <v-skeleton-loader type="list-item-three-line" :loading="loading" v-show="loading"></v-skeleton-loader>
      <div v-if="!loading">
        <go-back :routeName="previousPage" v-show="showGoBack"></go-back>
        <lista-tiendas-mobile :tiendas="sucursales"></lista-tiendas-mobile>
      </div>
    </div>
    <div v-else>
      <v-row v-show="loading">
        <v-col cols="4" v-for="i in 6" :key="`franquicia_col_${i}`">
          <v-skeleton-loader type="card" :loading="loading"></v-skeleton-loader>
        </v-col>
      </v-row>
      <lista-tiendas :tiendas="sucursales" v-if="!loading"></lista-tiendas>
    </div>
  </v-container>
</template>
<script>
import ListaTiendasMobile from '../components/mobile/ListaTiendasMobile.vue'
import GoBack from '../components/GoBack.vue'
import { mapActions } from 'vuex'
import { Plugins } from '@capacitor/core'
import ListaTiendas from '../components/ListaTiendas.vue'

const { Geolocation } = Plugins

export default {
  components: { ListaTiendasMobile, GoBack, ListaTiendas },
  name: 'SucursalesFranquicia',
  props: {
    id: {
      type: String
    }
  },
  methods: {
    ...mapActions('App', ['getSucursalesFranquicia', 'setAppCustomerPosition', 'setAppFranquiciaSeleccionada', 'setFranchiseLogo', 'setFranchiseName']),
    async getCustomerPosition (cords) {
      const { latitude, longitude } = cords.coords
      this.setAppCustomerPosition({
        latitude,
        longitude
      })
      await this.getSucursales()
    },
    async handlerGeopositionErros (erros) {
      if (erros.code === 1) {
        this.loading = false
        this.accesoDenegadoUsuario = true
      }
      await this.getSucursales()
    },
    getPermissions () {
      if (navigator.geolocation) {
        console.log(navigator.geolocation)
        navigator.geolocation.getCurrentPosition(this.getCustomerPosition, this.handlerGeopositionErros)
      } else {
        Geolocation.getCurrentPosition().then(coordinates => this.getCustomerPosition(coordinates)).catch(e => alert(e))
      }
    },
    aver () {
      navigator.permissions.revoke({ name: 'geolocation' }).then(console.log)
    },
    async getSucursales () {
      this.sucursales = await this.getSucursalesFranquicia(this.id)
      if (this.sucursales.length > 0) {
        const { Img, PrimaryColor, SecondaryColor, NombreFranquicia } = this.sucursales[0]
        await this.setFranchiseLogo(Img)
        await this.setFranchiseName(NombreFranquicia)
        this.$vuetify.theme.themes.light.primary = PrimaryColor
        this.$vuetify.theme.themes.light.secondary = SecondaryColor
      }
    }
  },
  data () {
    return {
      sucursales: [],
      loading: false,
      previousPage: {
        name: 'Tiendas'
      },
      showGoBack: false,
      accesoDenegadoUsuario: false
    }
  },
  created () {

  },
  async mounted () {
    await this.setAppFranquiciaSeleccionada(this.id)
    this.loading = true
    this.showGoBack = process.env.VUE_APP_LISTAR_FRANQUICIAS === 'FALSE'
    await this.getPermissions()
    this.loading = false
  }
}
</script>
