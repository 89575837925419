<template>
  <div :ref="grupo" style="max-height: 220px" class="overflow-y-auto">
    <combinado-card :producto="producto" v-for="(producto,i) in productos"
    @update-product="updateCombinados"
    @remove-product="removeFromCombinados"
    :key="`combinado_${i+producto.id}`"></combinado-card>
  </div>
</template>
<script>
import CombinadoCard from '../cards/CombinadoCard.vue'

export default {
  name: 'Combinados',
  components: { CombinadoCard },
  props: {
    grupo: {
      type: String
    },
    productos: {
      type: Array
    },
    cantidadMinima: {
      type: Number
    },
    cantidadMaxima: {
      type: Number
    },
    llevaCantidad: {
      type: Boolean
    },
    llevaImporte: {
      type: Boolean
    }
  },
  data () {
    return {
      combinados: [],
      cantidadTotal: 0,
      precioFinal: 0
    }
  },
  methods: {
    updateCombinados (combinado) {
      if (this.combinados.filter(e => e.id === combinado.id).length > 0) {
        this.combinados = this.combinados.filter(e => e.id !== combinado.id)
      }
      this.combinados.push({
        id: combinado.id,
        name: combinado.nombre,
        cant: combinado.cant,
        grupo: combinado.GuidGrupo,
        PrecioUnitario: combinado.PrecioUnitario
      })
      this.cantidadTotal = this.combinados.reduce((a, b) => a + b.cant, 0)
      if (this.llevaImporte) this.$emit('sumarImporte', combinado.PrecioUnitario)
    },
    removeFromCombinados (combinadoToRemove) {
      if (this.combinados.filter(e => e.id === combinadoToRemove.id).length > 0) {
        this.combinados = this.combinados.filter(e => e.id !== combinadoToRemove.id)
      }
      this.cantidadTotal = this.combinados.reduce((a, b) => a + b.cant, 0)
      if (this.llevaImporte) this.$emit('restarImporte', combinadoToRemove.PrecioUnitario)
    }
  },
  watch: {
    combinados (newValue, oldValue) {
      const payload = {
        grupo: this.grupo,
        items: newValue
      }
      this.$emit('updateCustomItem', payload)
    }
  }
}
</script>
