<template>
  <v-container>
    <v-row class="mt-4" v-show="!loading">
      <v-col v-for="(p,i) in products" :key="`${p.id}_${i}`" cols="3">
        <categoria-product-card :product="p"></categoria-product-card>
      </v-col>
    </v-row>
    <v-row class="mt-4" v-if="loading">
      <v-col v-for="i in 8" cols="3" :key="`${i}ab`">
        <v-skeleton-loader
            type="image, actions"
            :loading="loading">
        </v-skeleton-loader>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import CategoriaProductCard from './cards/CategoriaProductCard.vue'

export default {
  name: 'ProductosList',
  components: {
    CategoriaProductCard
  },
  computed: {
    ...mapState('Categorias', ['currentCategoria'])
  },
  methods: {
    ...mapActions('Productos', ['fetchProducts'])
  },
  data () {
    return {
      loading: false,
      products: []
    }
  },
  watch: {
    async currentCategoria (newVal, old) {
      this.loading = true
      this.products = await this.fetchProducts(newVal)
      this.loading = false
    }
  }
}
</script>
