<template>
  <div v-if="categorias">
    <go-back :routeName="appPreviousPage === 'Sucursales' ? goToSucursales : goToTiendas" v-if="!mesaId"></go-back>
    <v-row v-for="(c,i) in categorias" :key="`categoria_${i}`">
      <v-col cols="12">
        <categoria-card :categoria="c">
          <v-btn
            color="primary lighten-2"
            text
            @click.prevent="goToSubcategories(c.subcategorias)"
            v-if="c.subcategorias && c.subcategorias.length > 0"
          >
            VER
          </v-btn>
          <v-btn
            color="primary lighten-2"
            text
            @click.prevent="goToProducts(c)"
            v-else
          >
            {{ c.descripcion }}
          </v-btn>
        </categoria-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import CategoriaCard from '../cards/CategoriaCard.vue'
import GoBack from '../GoBack.vue'

export default {
  components: { GoBack, CategoriaCard },
  name: 'CategoriasHomeMobile',
  computed: {
    ...mapGetters('Categorias', ['categorias']),
    ...mapGetters('App', ['appPreviousPage', 'appFranquiciaSeleccionada'])
  },
  props: {
    mesaId: {
      type: String,
      default: null
    }
  },
  methods: {
    ...mapActions('Categorias', ['getCategorias']),
    goToProducts (category) {
      this.$store.commit('Categorias/setCategoria', category)
      if (this.mesaId) {
        this.$router.push({ name: 'MesaQRProductos' })
      } else {
        this.$router.push({ name: 'ProductosMobile' })
      }
    },
    goToSubcategories (categories) {
      console.log(categories)
    }
  },
  async mounted () {
    this.$vuetify.goTo(0)
    await this.getCategorias()
    this.goToSucursales = `/sucursales/${this.appFranquiciaSeleccionada}`
  },
  data () {
    return {
      goToTiendas: '/tiendas',
      goToSucursales: null
    }
  }
}
</script>
