<template>
  <v-container>
    <v-row class="mt-4" v-show="!loading">
      <v-col v-for="(p,i) in products" :key="i" :cols="cols">
        <v-card outlined>
          <v-img
            :src="p.detalle.srcImg"
            class="white--text align-end"
            gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
            height="200px"
            @click.prevent="goToProduct(p.id)"
          >
            <v-card-title v-text="p.nombre"></v-card-title>
          </v-img>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="mt-4" v-if="loading">
      <v-col v-for="i in 8" :cols="cols" :key="`${i}ab`">
        <v-skeleton-loader type="image" :loading="loading"></v-skeleton-loader>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapActions } from 'vuex'

export default {
  name: 'Similares',
  props: {
    id: {
      type: Number
    },
    catId: {
      type: Number
    }
  },
  computed: {
    cols () {
      if (this.$isMobile()) return '12'
      return '4'
    }
  },
  methods: {
    ...mapActions('Productos', ['getProductsByCatId']),
    goToProduct (id) {
      const code = this.$clientCode()
      this.$router.push({ name: 'Producto', params: { id: id, client: code } })
    }
  },
  data () {
    return {
      products: [],
      loading: false
    }
  },
  async mounted () {
    this.loading = true
    this.products = await this.getProductsByCatId({ catId: this.catId, prodId: this.id })
    this.loading = false
  }
}
</script>
