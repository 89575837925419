<template>
  <v-container>
    <TiendaCard :tienda="tienda" v-for="(tienda, i) in tiendas" :key="`tienda_${i}`"></TiendaCard>
  </v-container>
</template>
<script>
import TiendaCard from '../cards/TiendaCard.vue'

export default {
  name: 'ListaTiendasMobile',
  components: { TiendaCard },
  props: {
    tiendas: {
      type: Array
    }
  },
  mounted () {
    this.$vuetify.goTo(0)
  }
}
</script>
