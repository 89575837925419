<template>
  <v-form ref="cuponForm" v-model="isValid">
    <v-card>
    <v-card-title>
        Código de promocion
    </v-card-title>
    <v-card-subtitle>
        Ingrese su código de promocion para aplicar el descuento a su pedido
    </v-card-subtitle>
    <v-card-text v-if="validando">
        <div class="text-center">
        <v-progress-circular
            :size="50"
            color="primary"
            indeterminate
        ></v-progress-circular>
        </div>
    </v-card-text>
    <v-card-text v-else>
        <v-text-field
        @input="cuponInvalido = false"
        v-model="cupon"
        prepend-inner-icon="mdi-ticket-percent"
        outlined
        :rules="cuponRules"
        :error="cuponInvalido"
        required
        :error-messages="cuponInvalido ? 'El codigo ingresado es invalido' : []">
      </v-text-field>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
        <v-btn
            text
            @click.prevent="$emit('cerrar-cupon-card')"
          >CANCELAR</v-btn>
        <v-btn
            color="primary lighten-2"
            text
            @click.prevent="validar"
            :loading="validando"
            :disabled="!cupon || (cupon === '' || cupon.length < 8)"
          >
        APLICAR
      </v-btn>
    </v-card-actions>
</v-card>
  </v-form>
</template>
<script>
import { mapActions } from 'vuex'

export default {
  name: 'CuponCard',
  data () {
    return {
      validando: false,
      cupon: null,
      cuponInvalido: false,
      isValid: false,
      cuponRules: [
        v => !!v || 'Ingrese codigo de cupon',
        v => v.length === 8 || 'El codigo ingresado no tiene el formato correcto'
      ]
    }
  },
  methods: {
    ...mapActions('App', ['validarCupon', 'setCupon']),
    async validar () {
      if (this.$refs.cuponForm.validate()) {
        this.validando = true
        const resp = await this.validarCupon(this.cupon)
        if (!resp.data.IsValid) {
          this.validando = false
          this.cuponInvalido = true
          return
        }
        this.setCupon(resp.data)
        this.$emit('cerrar-cupon-card')
      }
    }
  }
}
</script>
