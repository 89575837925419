import axios from '@/axios/axios-api'
import clientCode from '@/helpers/getClientCode'

const actions = {
  async getProducts (context, params) {
    const code = clientCode()
    const response = await axios.get(`/api/productos/${code}/${params}`)
    response.data.forEach((producto) => {
      producto.cant = 1
      return producto
    })
    context.commit('setProducts', response.data)
  },
  async getProductById (context, params) {
    const response = await axios.get(`/api/producto/${params}`)
    response.data.cant = 1
    return response.data
  },
  async fetchProducts (context, params) {
    const code = clientCode()
    const response = await axios.get(`/api/productos/${code}/${params}`)
    return response.data
  },
  async getProductsByCatId (context, params) {
    const response = await axios.get(`/api/productos/${params.catId}?productoId=${params.prodId}`)
    response.data.forEach((producto) => { producto.cant = 1 })
    return response.data
  }
}

export default actions
