<template>
  <div>
    <div v-if="showAddLess">
      <v-icon @click.stop.prevent="restarCantidad" color="secondary">mdi-minus</v-icon>
      {{ tmpCant }}
      <v-icon color="secondary" @click.stop.prevent="sumarCantidad">mdi-plus</v-icon>
    </div>
    <v-btn color="secondary" text @click.stop.prevent="addProduct"
      :disabled="$parent.product.EnPausa"
      v-else>{{ !$parent.product.EnPausa ? 'Agregar' : 'Sin stock'}}
    </v-btn>
  </div>
</template>
<script>
import { mapActions } from 'vuex'

export default {
  name: 'BuyProduct',
  mounted () {
    this.tmpProduct = { ...this.$parent.product }
  },
  methods: {
    ...mapActions('Carrito', ['addItem', 'removeItemCarta']),
    addProduct () {
      const code = this.$clientCode()
      if (this.tmpProduct.tipo === 1) {
        this.tmpCant = 1
        this.tmpProduct.cant = this.tmpCant
        this.showAddLess = true
        this.addItem(this.tmpProduct)
      } else {
        if (this.$route.params.mesaId) {
          this.$router.push({
            name: 'ProductoQR',
            params: {
              client: code,
              id: this.tmpProduct.id,
              mesaId: this.$route.params.mesaId
            }
          })
        } else {
          this.$router.push({
            name: 'Producto',
            params: {
              client: code,
              id: this.tmpProduct.id
            }
          })
        }
      }
    },
    sumarCantidad () {
      this.tmpCant += 1
      this.tmpProduct.cant = this.tmpCant
      this.addItem(this.tmpProduct)
    },
    restarCantidad () {
      this.tmpCant -= 1
      this.tmpProduct.cant = this.tmpCant
      this.addItem(this.tmpProduct)
    }
  },
  data () {
    return {
      showAddLess: false,
      tmpCant: 1,
      tmpProduct: null
    }
  },
  watch: {
    tmpCant (newValue, oldValue) {
      if (newValue === 0) {
        this.showAddLess = false
        this.removeItemCarta(this.tmpProduct)
      }
    }
  }
}
</script>
